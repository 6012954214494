import { ApolloQueryResult } from '@apollo/client/core/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Query } from 'src/types';

type Keys = keyof Query;
// type ValueOf<T> = Query[keyof Query];
// type KeyQuery = Omit<Keys, '__typename'>;
// type ValueOfKeys = { [keys: string]: ValueOf<Keys> }; // string | number

export const query = (key: Keys) =>
  function <T>(source: Observable<ApolloQueryResult<T>>) {
    return source.pipe(
      map((result) => {
        if ((result?.error as any)?.[key] || result?.errors) {
          throw new Error(
            result.error?.message ||
              result.errors?.map((err) => err.message)?.join(', ')
          );
        } else {
          return (result?.data as any)?.[key];
        }
        // if ((result?.data as any)?.[key] || !(result?.error as any)?.[key]) {
      })
    );
  };
