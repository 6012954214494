import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared-modules/stateless/snack-bar/services';

import { OrganizationService } from '../../services';
import * as fromActions from '../actions';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private snackBar: SnackBarService
  ) {}

  // updateOrganization Effects
  updateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateOrganization),
      switchMap(({ payload }) =>
        this.organizationService
          .updateOrganization(
            payload.organizationId,
            payload.input,
            payload.image
          )
          .pipe(
            map((result) =>
              fromActions.updateOrganizationSuccess({ payload: result })
            ),
            catchError((error) =>
              of(fromActions.updateOrganizationFailure({ payload: error }))
            )
          )
      )
    )
  );

  updateOrganizationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateOrganizationSuccess),
      map(({ payload }) => {
        this.snackBar.open(
          `Organization ${payload?.name} is updated successfully.`,
          'SUCCESS',
          3000
        );
        return fromActions.loadOrganization({
          payload: { organizationId: payload._id },
        });
      })
    );
  });

  updateOrganizationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.updateOrganizationFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to update organization, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  // loadUniqueDomain Effects
  loadUniqueDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUniqueDomain),
      switchMap(({ payload }) =>
        this.organizationService.loadUniqueDomain(payload).pipe(
          map((result) =>
            fromActions.loadUniqueDomainSuccess({ payload: result })
          ),
          catchError((error) =>
            of(fromActions.loadUniqueDomainFailure({ payload: error }))
          )
        )
      )
    )
  );

  loadUniqueDomainFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loadUniqueDomainFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to generate unique domain, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  // loadOrganization Effects
  loadOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadOrganization),
      switchMap(({ payload }) =>
        this.organizationService.loadOrganization(payload).pipe(
          map((result) =>
            fromActions.loadOrganizationSuccess({ payload: result })
          ),
          catchError((error) =>
            of(fromActions.loadOrganizationFailure({ payload: error }))
          )
        )
      )
    )
  );

  loadOrganizationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loadOrganizationFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to load organization, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );
}
