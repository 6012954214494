import { createAction, props } from '@ngrx/store';

export const loadTimeZones = createAction('[App] load time zones');
export const loadTimeZonesSuccess = createAction(
  '[App] load time zones success',
  props<{ payload: string[] }>()
);
export const loadTimeZonesFailure = createAction(
  '[App] load time zones failure',
  props<{ payload: Error }>()
);
