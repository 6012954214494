import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import * as fromTypes from 'src/types';

@Component({
  selector: 'app-forget-password-form',
  templateUrl: './forget-password-form.component.html',
  styleUrls: ['./forget-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgetPasswordFormComponent implements OnInit, OnChanges {
  form: FormGroup;
  isSubmitted = false;
  @Output() submitted = new EventEmitter();
  @Input() forgetPasswordPending: boolean;
  @Input() serverError: fromTypes.Maybe<Error | undefined>;

  get emailValue(): string {
    return this.form?.get('email')?.value;
  }

  get emailRequired(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('email')?.invalid &&
      !!this.form.get('email')?.hasError('required')
    );
  }

  get emailPatternInvalid(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('email')?.invalid &&
      !!this.form.get('email')?.hasError('email') &&
      !this.form.get('email')?.hasError('required')
    );
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [CustomValidators.required, Validators.email]],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverError && changes.serverError.currentValue) {
      // if (this.serverError?.name === 'email') {
      this.form.get('email')?.setErrors({ emailServerError: true });
      // }
    }
  }

  onSubmit(): void {
    this.isSubmitted = true;
    const { value, valid } = this.form;

    if (valid) {
      this.submitted.emit(value);
    }
  }
}
