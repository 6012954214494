import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectionService } from 'ng-connection-service';
import { SnackBarService } from './shared-modules/stateless/snack-bar/services';

@UntilDestroy()
@Component({
  selector: 'app-root',
  template: `<ngx-loading-bar [color]="'#9e1e22'"></ngx-loading-bar
    ><router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private connectionService: ConnectionService,
    private snackBarService: SnackBarService
  ) {}
  ngOnInit(): void {
    this.connectionService
      .monitor()
      .pipe(untilDestroyed(this))
      .subscribe((isConnected) => {
        this.snackBarService.dismissAll();
        if (!isConnected) {
          this.snackBarService.open(
            'You seem to be offline please check your internet connection.',
            'WARNING',
            null
          );
        }
      });
  }
}
