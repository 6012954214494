<div class="auth-wrapper">
  <div class="auth">
    <img
      src="assets/hub/logo.svg"
      class="auth__logo"
      role="img"
      alt="Assess Talent Management Logo"
      aria-label="Assess Talent Management Logo"
    />

    <p class="auth__title">
      Welcome Back,<br />We hope you enjoy creating assessments.
    </p>

    <!-- <div class="auth__tab">
      <a class="auth__tab__button auth__tab__button--selected">Login</a>
      <a
        class="auth__tab__button"
        [routerLink]="['/auth/signup']"
        queryParamsHandling="merge"
      >
        Create Account
      </a>
    </div> -->

    <app-signin-form
      (submitted)="onSubmit($event)"
      [signinPending]="(signinPending$ | async) ? true : false"
    ></app-signin-form>
  </div>
</div>
