export const ROLES = [
  'Administrative Assistant',
  'Receptionist',
  'Office Manager',
  'Auditing Clerk',
  'Bookkeeper',
  'Account Executive',
  'Branch Manager',
  'Business Manager',
  'Quality Control Coordinator',
  'Administrative Manager',
  'Chief Executive Officer',
  'Business Analyst',
  'Risk Manager',
  'Human Resources',
  'Office Assistant',
  'Secretary',
  'Office Clerk',
  'File Clerk',
  'Account Collector',
  'Administrative Specialist',
  'Executive Assistant',
  'Program Administrator',
  'Program Manager',
  'Administrative Analyst',
  'Data Entry',
];
