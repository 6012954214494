import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ForgetPasswordComponent } from '../../../forget-password/containers';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordPending$: Observable<boolean>;
  resetPasswordCode: number;
  userId: string;
  constructor(
    private store: Store<fromStore.IRootState>,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.resetPasswordPending$ = this.store.pipe(
      select(fromStore.getAuthResetPasswordPending)
    );

    this.store
      .pipe(
        select(fromStore.getAuthUserId),
        untilDestroyed(this),
        filter((userId) => !!userId)
      )
      .subscribe((userId) => {
        // console.log('{commit}', userId);
        this.userId = userId as string;
      });
  }

  onSubmit($event: { newPassword: string; resetPasswordCode: number }) {
    this.store.dispatch(
      fromActions.resetPassword({
        payload: {
          userId: this.userId,
          newPassword: $event.newPassword,
          resetPasswordCode: $event.resetPasswordCode,
        },
      })
    );
  }

  openDialog() {
    this.dialog.closeAll();
    this.dialog.open(ForgetPasswordComponent, {
      autoFocus: false,
    });
  }
}
