import { createAction, props } from '@ngrx/store';

import * as fromModels from '../../models';
import * as fromTypes from 'src/types';

/** Signin Actions */
export const signin = createAction(
  '[Auth] signin',
  props<{ payload: fromTypes.SigninInput }>()
);
export const signinSuccess = createAction(
  '[Auth] signin success',
  props<{ payload: fromTypes.SigninResponse }>()
);
export const signinFailure = createAction(
  '[Auth] signin failure',
  props<{ payload: Error }>()
);

/** Signup Actions */
export const signup = createAction(
  '[Auth] signup',
  props<{ payload: fromTypes.SignupInput }>()
);
export const signupSuccess = createAction(
  '[Auth] signup success',
  props<{ payload: fromTypes.SignupResponse }>()
);
export const signupFailure = createAction(
  '[Auth] signup failure',
  props<{ payload: Error }>()
);

// load user actions
export const loadUser = createAction('[Auth] load user');
export const loadUserSuccess = createAction(
  '[Auth] load user success',
  props<{ payload: fromTypes.UserResponse }>()
);
export const loadUserFailure = createAction(
  '[Auth] load user failure',
  props<{ payload: Error }>()
);

/** Logout Actions */
export const logout = createAction('[Auth] logout');
export const logoutSuccess = createAction('[Auth] logout success');
export const logoutFailure = createAction(
  '[Auth] logout failure',
  props<{ payload: Error }>()
);

// Forget Password Actions
export const forgetPassword = createAction(
  '[Auth] forget password',
  props<{ payload: fromTypes.ForgetPasswordInput }>()
);
export const forgetPasswordSuccess = createAction(
  '[Auth] forget password success',
  props<{ payload: fromTypes.ForgetPasswordResponse }>()
);
export const forgetPasswordFailure = createAction(
  '[Auth] forget password failure',
  props<{ payload: Error }>()
);

// Reset Password Actions
export const resetPassword = createAction(
  '[Auth] reset password',
  props<{ payload: fromTypes.ResetPasswordInput }>()
);
export const resetPasswordSuccess = createAction(
  '[Auth] reset password success'
);
export const resetPasswordFailure = createAction(
  '[Auth] reset password failure',
  props<{ payload: Error }>()
);

// Resend Verification Actions
export const resendVerification = createAction(
  '[Auth] resend verification',
  props<{ payload: fromTypes.ResendVerificationCodeInput }>()
);
export const resendVerificationSuccess = createAction(
  '[Auth] resend verification success'
);
export const resendVerificationFailure = createAction(
  '[Auth] resend verification failure',
  props<{ payload: Error }>()
);

// Verify Account Actions
export const verifyAccount = createAction(
  '[Auth] verify account',
  props<{ payload: fromTypes.VerifyInput }>()
);
export const verifyAccountSuccess = createAction(
  '[Auth] verify account success',
  props<{ payload: fromTypes.SigninResponse }>()
);
export const verifyAccountFailure = createAction(
  '[Auth] verify account failure',
  props<{ payload: Error }>()
);
