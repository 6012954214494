import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Config } from 'ng-otp-input/lib/models/config';
import { CustomValidators } from 'src/app/utils/custom-validators';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent implements OnInit, OnChanges {
  form: FormGroup;
  isSubmitted = false;
  @Output() submitted = new EventEmitter<{
    newPassword: string;
    resetPasswordCode: number;
  }>();
  @Input() resetPasswordPending: boolean;

  isShown = { newPassword: false, confirmNewPassword: false };
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;
  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '44px',
      height: '44px',
    },
  };

  get newPasswordValue(): string {
    return this.form?.get('newPassword')?.value;
  }

  get newPasswordRequired(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('newPassword')?.invalid &&
      !!this.form.get('newPassword')?.hasError('required')
    );
  }

  get newPasswordMinlength(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('newPassword')?.invalid &&
      !!this.form.get('newPassword')?.hasError('minlength') &&
      !this.form.get('newPassword')?.hasError('required')
    );
  }

  get confirmNewPasswordValue(): string {
    return this.form?.get('confirmNewPassword')?.value;
  }

  get confirmNewPasswordRequired(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('confirmNewPassword')?.invalid &&
      !!this.form.get('confirmNewPassword')?.hasError('required')
    );
  }

  get confirmNewPasswordMinlength(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('confirmNewPassword')?.invalid &&
      !!this.form.get('confirmNewPassword')?.hasError('minlength') &&
      !this.form.get('confirmNewPassword')?.hasError('required')
    );
  }

  get passwordsNotTheSame(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      this.form.invalid &&
      this.form.hasError('notTheSame') &&
      !this.form.get('confirmNewPassword')?.hasError('required') &&
      !this.form.get('confirmNewPassword')?.hasError('minlength')
    );
  }

  get resetPasswordCodeRequired(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('resetPasswordCode')?.invalid &&
      !!this.form.get('resetPasswordCode')?.hasError('required')
    );
  }

  get resetPasswordCodeServerError(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('resetPasswordCode')?.invalid &&
      !!this.form.get('resetPasswordCode')?.hasError('serverError') &&
      !this.form.get('resetPasswordCode')?.hasError('required')
    );
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        newPassword: [
          '',
          [CustomValidators.required, CustomValidators.minLength(6)],
        ],
        confirmNewPassword: [
          '',
          [CustomValidators.required, CustomValidators.minLength(6)],
        ],
        resetPasswordCode: [, [CustomValidators.required]],
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverError && changes.serverError.currentValue) {
      // if (this.serverError?.name === 'resetPasswordCode') {
      this.form.get('resetPasswordCode')?.setErrors({ serverError: true });
      // }
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    const { value, valid } = this.form;

    if (valid) {
      this.submitted.emit({
        newPassword: value.newPassword,
        resetPasswordCode: value.resetPasswordCode,
      });
    }
  }

  onOtpChange($event: string) {
    this.form.get('resetPasswordCode')?.patchValue(+$event);
  }

  private checkPasswords(group: FormGroup) {
    let pass = group.get('newPassword')?.value;
    let confirmPass = group.get('confirmNewPassword')?.value;

    return pass === confirmPass ? null : { notTheSame: true };
  }
}
