import { mergeMap, switchMap } from 'rxjs/operators';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  DefaultOptions,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { onError } from '@apollo/client/link/error';
import { AuthService } from './services';
// import { defaultDataIdFromObject } from '@apollo/client';

const uri = environment.graphqlURL; // <-- add the URL of the GraphQL server here
const secondUri = 'https://48p1r2roz4.sse.codesandbox.io/'; // from get started (Doc)
const thirdUri = 'https://graphql-voter-app.herokuapp.com/'; // queries
// const uri = 'https://graphql-voter-app.herokuapp.com/'; // queries

export function createApollo(
  httpLink: HttpLink,
  authService: AuthService
): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'x-refresh-token': refreshToken ? `Bearer ${refreshToken}` : '',
        },
      };
    }
  });
  const http = httpLink.create({ uri });
  // react https://lucasmcgartland.medium.com/refreshing-token-based-authentication-with-apollo-client-2-0-7d45c20dc703

  const middleware = new ApolloLink((operation, forward) => {
    // operation.setContext({
    //   headers: new HttpHeaders().set(
    //     'Authorization',
    //     `Bearer ${localStorage.getItem('accessToken') || null}`
    //   ),
    // });
    return forward(operation);
  });

  //   clientErrors(pin):
  // networkError(pin):null
  // message(pin):"Incorrect email or password."

  // const error = onError(({ networkError }) => {
  //   if (networkError.status === 401) {
  //     auth.logout();
  //   }
  // });
  /*const errorLink = onError(
    ({ graphQLErrors, networkError, operation, response, forward }) => {
      // if (operation.operationName === 'IgnoreErrorsQuery') {
      //   response.errors = null;
      // }
      console.log('{handling}', operation);
      console.log('{handling 2}', response);
      return forward(operation);
    }
  );*/
  /* HANDLING ERROR */
  /*const errorLink = onError(
    // forward
    ({ graphQLErrors, networkError, operation, forward }): any => {
      console.log('errrrrr', graphQLErrors);

      if (graphQLErrors) {
        console.log('errrrrr');

        for (let err of graphQLErrors) {
          console.log(err.extensions.code);

          switch (err.extensions.code) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            case 'INTERNAL_SERVER_ERROR': // UNAUTHENTICATED
              // Modify the operation context with a new token
              // const oldHeaders = operation.getContext().headers;
              // operation.setContext({
              //   headers: {
              //     ...oldHeaders,
              //     authorization: getNewToken(),
              //   },
              // });
              // return forward(operation);
              return authService
                .refreshToken()
                .pipe(mergeMap((res) => forward(operation) as any));
            // Retry the request, returning the new observable
          }
        }
      }

      // To retry on network errors, we recommend the RetryLink
      // instead of the onError link. This just logs the error.
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      return forward(operation);
    }
  );*/

  // const errorLink = onError(({ graphQLErrors, networkError }) => {
  //   if (graphQLErrors)
  //     graphQLErrors.map(({ extensions, message, locations, path }) =>
  //       console.log(
  //         `[GraphQL error]: Extensions: ${extensions?.exception?.status}, Message: ${message}, Location: ${locations}, Path: ${path}`
  //       )
  //     );
  //   if (networkError) console.log(`[Network error]: ${networkError}`);
  // });

  // const link = errorLink.concat(http);
  const link = middleware.concat(http); //.concat(errorLink);

  // comment it for uploading file
  // const link = ApolloLink.from([basic, auth, http]).concat(errorLink);
  const cache = new InMemoryCache();

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };

  return {
    link,
    cache,
    defaultOptions,
    connectToDevTools: !environment.production,
  };
}

export function createNamedApollo(
  httpLink: HttpLink
): Record<string, ApolloClientOptions<any>> {
  return {
    second: {
      name: 'second', // optional
      link: httpLink.create({ uri: secondUri }),
      cache: new InMemoryCache(),
    },
    third: {
      name: 'third', // optional
      link: httpLink.create({ uri: thirdUri }),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
    // {
    //   provide: APOLLO_NAMED_OPTIONS,
    //   useFactory: createNamedApollo,
    //   deps: [HttpLink],
    // },
  ],
})
export class GraphQLModule {}
