import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';

import * as fromComponents from './components';

import * as fromServices from './services';
@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [CommonModule, ReactiveFormsModule, FlexLayoutModule],
  providers: [...fromServices.SERVICES],
  exports: [fromComponents.SnackBarComponent],
})
export class SnackBarModule {}
