<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form__group">
    <mat-label class="form__group__label" for="verificationCode"
      >Enter Code</mat-label
    >

    <ng-otp-input
      id="verificationCode"
      [config]="config"
      (onInputChange)="onOtpChange($event)"
    ></ng-otp-input>
    <!-- aria-label="add Verification Code to verify account" -->

    <mat-error *ngIf="verificationCodeRequired">
      Verification Code is required</mat-error
    >
    <mat-error *ngIf="verificationCodeServerError"
      >Invalid verification code</mat-error
    >
  </div>

  <button
    mat-flat-button
    color="primary"
    class="form__button"
    [type]="verifyPending ? 'button' : 'submit'"
    role="button"
    aria-label="Verify"
    [disabled]="verifyPending"
  >
    {{ verifyPending ? "verifying..." : "Verify" }}
  </button>
</form>
