import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mutation, query } from 'src/app/utils/rxjs-operators';
import * as fromGQL from 'src/generated/graphql';
import * as fromTypes from 'src/types';

@Injectable()
export class MyOrganizationsService {
  constructor(
    private createOrganizationGQL: fromGQL.CreateOrganizationGQL,
    private myOrganizationsGQL: fromGQL.MyOrganizationsGQL
  ) {}

  loadMyOrganizations(): Observable<fromTypes.OrganizationResponse[]> {
    return this.myOrganizationsGQL.fetch().pipe(query('myOrganizations'));
  }

  createOrganization(
    input: fromTypes.CreateOrganizationInput,
    image: fromTypes.Scalars['Upload']
  ): Observable<fromTypes.OrganizationResponse> {
    const payload = typeof image === 'string' ? { input } : { input, image };
    return this.createOrganizationGQL
      .mutate(payload, { context: { useMultipart: true } })
      .pipe(mutation('createOrganization'));
  }
}
