<button type="button" class="back" (click)="openDialog()">
  <i class="ph-arrow-left"></i>
</button>
<div class="auth-wrapper">
  <div class="auth">
    <img
      src="assets/hub/logo.svg"
      class="auth__logo"
      role="img"
      alt="Assess Talent Management Logo"
      aria-label="Assess Talent Management Logo"
    />
    <p class="title">
      Enter your new password. <br />
      Make sure that you entered a password you won’t forget
    </p>

    <app-reset-password-form
      (submitted)="onSubmit($event)"
      [resetPasswordPending]="(resetPasswordPending$ | async) ? true : false"
    ></app-reset-password-form>
  </div>
</div>
