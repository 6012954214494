import { AuthService } from './auth.service';
import { DatesService } from './dates.service';
import { MyOrganizationsService } from './my-organizations.service';
import { OrganizationService } from './organization.service';
import { RedirectService } from './redirect.service';
import { SystemLanguagesService } from './system-languages.service';
import { TimeZonesService } from './timezones.service';

export const SERVICES = [
  AuthService,
  RedirectService,
  SystemLanguagesService,
  TimeZonesService,
  MyOrganizationsService,
  OrganizationService,
  DatesService,
];

export { AuthService } from './auth.service';
export { RedirectService } from './redirect.service';
export { SystemLanguagesService } from './system-languages.service';
export { TimeZonesService } from './timezones.service';
export { MyOrganizationsService } from './my-organizations.service';
export { OrganizationService } from './organization.service';
export { DatesService } from './dates.service';
