<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form__group">
    <mat-label class="form__group__label" for="password">Password</mat-label>
    <div class="input-wrapper with-prefix with-suffix">
      <i
        class="icon prefix ph-lock"
        [ngClass]="{ 'has-value': newPasswordValue }"
      ></i>
      <input
        [type]="isShown.newPassword ? 'text' : 'password'"
        placeholder="Enter your Password"
        formControlName="newPassword"
        aria-label="add new password to reset password"
      />
      <button
        class="icon suffix"
        [ngClass]="{ 'has-value': newPasswordValue }"
        (click)="isShown.newPassword = !isShown.newPassword"
      >
        <i
          [ngClass]="{
            'ph-eye': isShown.newPassword,
            'ph-eye-closed': !isShown.newPassword,
            'has-value': newPasswordValue
          }"
        ></i>
      </button>
    </div>

    <mat-error *ngIf="newPasswordRequired">New password is required </mat-error>
    <mat-error *ngIf="newPasswordMinlength"
      >you must enter 6 characters minimum</mat-error
    >
  </div>

  <div class="form__group">
    <mat-label class="form__group__label" label="password"
      >Confirm Password</mat-label
    >
    <div class="input-wrapper with-prefix with-suffix">
      <i
        class="icon prefix ph-lock"
        [ngClass]="{ 'has-value': confirmNewPasswordValue }"
      ></i>
      <input
        id="confirmPassword"
        [type]="isShown.confirmNewPassword ? 'text' : 'password'"
        placeholder="Enter your Password"
        formControlName="confirmNewPassword"
        aria-label="add confirm new password to reset password"
      />
      <button
        class="icon suffix"
        [ngClass]="{ 'has-value': confirmNewPasswordValue }"
        (click)="isShown.confirmNewPassword = !isShown.confirmNewPassword"
      >
        <i
          [ngClass]="{
            'ph-eye': isShown.confirmNewPassword,
            'ph-eye-closed': !isShown.confirmNewPassword,
            'has-value': confirmNewPasswordValue
          }"
        ></i>
      </button>
    </div>

    <mat-error *ngIf="confirmNewPasswordRequired">
      Confirm Password is required
    </mat-error>
    <mat-error *ngIf="confirmNewPasswordMinlength"
      >you must enter 6 characters minimum</mat-error
    >
    <mat-error *ngIf="passwordsNotTheSame">Passwords does not match </mat-error>
  </div>

  <div class="form__group">
    <mat-label class="form__group__label" for="resetPasswordCode"
      >Enter Code</mat-label
    >

    <ng-otp-input
      id="resetPasswordCode"
      #ngOtpInput
      (onInputChange)="onOtpChange($event)"
      [config]="config"
    ></ng-otp-input>
    <mat-error *ngIf="resetPasswordCodeRequired">
      Reset Password Code is required</mat-error
    >
    <mat-error *ngIf="resetPasswordCodeServerError"
      >Invalid reset password code</mat-error
    >
  </div>

  <button
    mat-flat-button
    color="primary"
    class="form__button"
    [type]="resetPasswordPending ? 'button' : 'submit'"
    role="button"
    aria-label="Confirm new password to reset password"
    [disabled]="resetPasswordPending"
  >
    {{ resetPasswordPending ? "resetting ....." : "Confirm New Password" }}
  </button>
</form>
