import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mutation, query } from 'src/app/utils/rxjs-operators';
import * as fromGQL from 'src/generated/graphql';
import * as fromTypes from 'src/types';

@Injectable()
export class OrganizationService {
  constructor(
    private myOrganizationGQL: fromGQL.MyOrganizationGQL,
    private generateUniqueDomainGQL: fromGQL.GenerateUniqueDomainGQL,
    private updateOrganizationGQL: fromGQL.UpdateOrganizationGQL
  ) {}

  loadOrganization(payload: {
    organizationId: string;
  }): Observable<fromTypes.MyOrganizationWithJobTitleResponse> {
    return this.myOrganizationGQL.fetch(payload).pipe(query('myOrganization'));
  }

  loadUniqueDomain(input: { organizationName: string }): Observable<string> {
    return this.generateUniqueDomainGQL
      .mutate(input)
      .pipe(mutation('generateUniqueDomain'));
  }

  updateOrganization(
    organizationId: string,
    input: fromTypes.UpdateOrganizationInput,
    image: fromTypes.Scalars['Upload']
  ): Observable<fromTypes.MyOrganizationWithJobTitleResponse> {
    const payload =
      typeof image === 'string'
        ? { organizationId, input }
        : { organizationId, input, image };
    return this.updateOrganizationGQL
      .mutate(payload, { context: { useMultipart: true } })
      .pipe(mutation('updateOrganization'));
  }
}
