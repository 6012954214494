import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';

import * as fromModels from 'src/app/models';
import { MatDialog } from '@angular/material/dialog';
import { SigninComponent } from '../../../signin/containers';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  forgetPasswordPending$: Observable<boolean>;
  forgetPasswordError$: Observable<Error | undefined>;

  constructor(
    private store: Store<fromStore.IRootState>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.forgetPasswordPending$ = this.store.pipe(
      select(fromStore.getAuthForgetPasswordPending)
    );
    this.forgetPasswordError$ = this.store.pipe(
      select(fromStore.getAuthForgetPasswordError)
    );
  }

  onSubmit(email: { email: string }) {
    this.store.dispatch(fromActions.forgetPassword({ payload: email }));
  }

  openDialog() {
    this.dialog.closeAll();
    this.dialog.open(SigninComponent);
  }
}
