import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared-modules/stateless/snack-bar/services';
import { ResetPasswordComponent } from 'src/app/views/auth/views/reset-password/containers';
import { SigninComponent } from 'src/app/views/auth/views/signin/containers';
import { VerifyComponent } from 'src/app/views/auth/views/verify/containers';

import { AuthService, RedirectService } from '../../services';
import * as fromActions from '../actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private redirectService: RedirectService,
    private snackBar: SnackBarService
  ) {}

  // Signin Effects
  signin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.signin),
      switchMap(({ payload }) =>
        this.authService.signin(payload).pipe(
          map((result) => fromActions.signinSuccess({ payload: result })),
          catchError((error) =>
            of(fromActions.signinFailure({ payload: error }))
          )
        )
      )
    )
  );

  signinSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.signinSuccess),
      map(({ payload }) => {
        if (payload?.isVerified) {
          this.authService.setRefreshToken(payload?.refreshToken);
          this.authService.setAccessToken(payload?.accessToken);

          this.snackBar.open(
            'You have successfully logged in',
            'SUCCESS',
            3000
          );
          this.redirectService.getRoute();
        } else {
          this.dialog.closeAll();
          this.dialog.open(VerifyComponent, { autoFocus: false });
        }
        return fromActions.loadUser();
        // this.redirectService.getRoute();
      })
    );
  });

  signinFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signinFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to signin, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  //Signup Effects
  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.signup),
      switchMap(({ payload }) =>
        this.authService.signup(payload).pipe(
          map((data) => fromActions.signupSuccess({ payload: data })),
          catchError((error) =>
            of(fromActions.signupFailure({ payload: error }))
          )
        )
      )
    )
  );

  signupSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupSuccess),
        tap(({ payload }) => {
          this.snackBar.open(
            'You have successfully signed up',
            'SUCCESS',
            3000
          );

          this.dialog.closeAll();
          this.dialog.open(VerifyComponent, { autoFocus: false });
        })
      );
    },
    { dispatch: false }
  );

  signupFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.signupFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to sign up, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );

  //Logout effect

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.logout),
      switchMap(() =>
        this.authService.logout().pipe(
          map(() => fromActions.logoutSuccess()),
          catchError((error) =>
            of(fromActions.logoutFailure({ payload: error }))
          )
        )
      )
    );
  });

  logoutSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.logoutSuccess),
        tap(() => {
          this.authService.removeRefreshToken();
          this.authService.removeAccessToken();

          this.router.navigate(['/']);
        })
      );
    },
    { dispatch: false }
  );

  logoutFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.logoutFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to logout, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );

  // dispatch actions by guard
  // initStatic$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ROOT_EFFECTS_INIT),
  //     mergeMap(() => [
  //       fromActions.loadSystemLanguages(),
  //       fromActions.loadTimeZones(),
  //     ])
  //   )
  // );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      filter(() => !!this.authService.getAccessToken()),
      map(() => fromActions.loadUser())
    )
  );

  // check it later
  // case when access token is not valid, then logout it will work
  // and signin, dialog is not close
  // logging before and after getAccessToken
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadUser),
      filter(() => !!this.authService.getAccessToken()),
      exhaustMap(() =>
        this.authService.getCurrentUser().pipe(
          map((data) => fromActions.loadUserSuccess({ payload: data })),
          catchError((error) =>
            of(fromActions.loadUserFailure({ payload: error }))
          )
        )
      )
    )
  );

  // dialog
  loadUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadUserSuccess),
      tap(({ payload }) => {
        this.dialog.closeAll();
        // this.dialog.open(VerifiedComponent);
      }),
      mergeMap(() => [fromActions.loadMyOrganizations()])
    );
  });

  forgetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.forgetPassword),
      switchMap(({ payload }) =>
        this.authService.forgetPassword(payload).pipe(
          map((data) => fromActions.forgetPasswordSuccess({ payload: data })),
          catchError((error) =>
            of(
              fromActions.forgetPasswordFailure({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  forgetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.forgetPasswordSuccess),
        tap(({ payload }) => {
          this.snackBar.open('Check your email', 'SUCCESS', 5000);

          this.dialog.closeAll();
          this.dialog.open(ResetPasswordComponent, {
            autoFocus: false,
          });
        })
      );
    },
    { dispatch: false }
  );

  forgetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.forgetPasswordFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to forget password, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );

  // Reset Password Effects
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetPassword),
      switchMap(({ payload }) =>
        this.authService.resetPassword(payload).pipe(
          map(() => fromActions.resetPasswordSuccess()),
          catchError((error) =>
            of(
              fromActions.resetPasswordFailure({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  resetPasswordSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resetPasswordSuccess),
        tap(() => {
          this.snackBar.open(
            'Your password has been reset successfully, you can signin',
            'SUCCESS',
            3000
          );

          this.dialog.closeAll();
          this.dialog.open(SigninComponent);
        })
      );
    },
    { dispatch: false }
  );

  resetPasswordFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resetPasswordFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to reset password, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );

  // Resent Verification Effects
  resendVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resendVerification),
      switchMap(({ payload }) =>
        this.authService.resendVerification(payload).pipe(
          map(() => fromActions.resendVerificationSuccess()),
          catchError((error) =>
            of(
              fromActions.resendVerificationFailure({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  resendVerificationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendVerificationSuccess),
        tap(() => {
          this.snackBar.open(
            'You have successfully resent verification code',
            'SUCCESS',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  resendVerificationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.resendVerificationFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to resend verification, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );

  // verify account Effects
  verifyAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.verifyAccount),
      switchMap(({ payload }) =>
        this.authService.verify(payload).pipe(
          map((result) =>
            fromActions.verifyAccountSuccess({ payload: result })
          ),
          catchError((error) =>
            of(
              fromActions.verifyAccountFailure({
                payload: error,
              })
            )
          )
        )
      )
    )
  );

  verifyAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.verifyAccountSuccess),
      map(({ payload }) => {
        this.snackBar.open(
          'You have successfully verify your account',
          'SUCCESS',
          3000
        );

        this.dialog.closeAll();
        this.authService.setRefreshToken(payload?.refreshToken);
        this.authService.setAccessToken(payload?.accessToken);

        this.redirectService.getRoute();

        return fromActions.loadUser();

        // this.redirectService.getRoute();
      })
    );
  });

  verifyAccountFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.verifyAccountFailure),
        tap(({ payload }) =>
          this.snackBar.open(
            `Failed to verify account, ${payload?.message}`,
            'FAILURE',
            3000
          )
        )
      );
    },
    { dispatch: false }
  );
}
