import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromOrganizationReducer from '../reducers/organization.reducer';

export const getOrganizationUpdatePending = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationUpdatePending
);

export const getOrganizationUpdateError = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationUpdateError
);

export const getOrganizationPending = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationPending
);

export const getOrganizationError = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationError
);

export const getOrganizationDomainPending = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationDomainPending
);

export const getOrganizationDomainError = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationDomainError
);

export const getOrganizationDomain = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationDomain
);

export const getOrganizationDetail = createSelector(
  fromRoot.getOrganizationState,
  fromOrganizationReducer.getOrganizationDetail
);

export const getOrganizationApiKey = createSelector(
  getOrganizationDetail,
  (detail) => detail?.adsApiKey
);

export const getOrganizationAccess = createSelector(
  getOrganizationDetail,
  (detail) => detail?.access
);
