import { createAction, props } from '@ngrx/store';
import * as fromTypes from 'src/types';

/**************************** Queries ****************************/

/** loadMyOrganizations Actions */
export const loadMyOrganizations = createAction('[App] load my organizations');
export const loadMyOrganizationsSuccess = createAction(
  '[App] load my organizations success',
  props<{ payload: fromTypes.OrganizationResponse[] }>()
);
export const loadMyOrganizationsFailure = createAction(
  '[App] load my organizations failure',
  props<{ payload: Error }>()
);

/**************************** Mutations ****************************/

/** createOrganization Actions */
export const createOrganization = createAction(
  '[App] create organization',
  props<{ payload: fromTypes.MutationCreateOrganizationArgs }>()
);
export const createOrganizationSuccess = createAction(
  '[App] create organization success',
  props<{ payload: fromTypes.OrganizationResponse }>()
);
export const createOrganizationFailure = createAction(
  '[App] create organization failure',
  props<{ payload: Error }>()
);
