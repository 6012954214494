import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSystemLanguagesReducer from '../reducers/system-languages.reducer';

export const getSystemLanguagesSelectAll = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.selectAll
);

export const getSystemLanguagesSelectEntities = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.selectEntities
);

export const getSystemLanguagesSelectIds = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.selectIds
);

export const getSystemLanguagesSelectTotal = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.selectTotal
);

export const getSystemLanguagesPending = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.getSystemLanguagesPending
);

export const getSystemLanguagesError = createSelector(
  fromFeature.getSystemLanguagesState,
  fromSystemLanguagesReducer.getSystemLanguagesError
);
