import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromTypes from 'src/types';

import * as fromActions from '../actions';

export const myOrganizationAdapter =
  createEntityAdapter<fromTypes.OrganizationResponse>({
    selectId: (org) => org?._id,
  });

export interface IMyOrganizationsState
  extends EntityState<fromTypes.OrganizationResponse> {
  pending: boolean;
  error?: Error;

  createPending: boolean;
  createError?: Error;
  created?: fromTypes.OrganizationResponse;
}

export const initialState: IMyOrganizationsState =
  myOrganizationAdapter.getInitialState({
    pending: false,
    createPending: false,
  });

export const myOrganizationReducer = createReducer(
  initialState,
  // Handle loadMyOrganizations actions
  on(fromActions.loadMyOrganizations, (state) => {
    /** check if it's first page loaded clear entities to start from empty array else just update pending = true  */
    return myOrganizationAdapter.setAll([], {
      ...state,
      pending: true,
      error: undefined,
    });
  }),
  on(fromActions.loadMyOrganizationsSuccess, (state, { payload }) =>
    myOrganizationAdapter.setAll(payload || [], {
      ...state,
      pending: false,
    })
  ),
  on(fromActions.loadMyOrganizationsFailure, (state, { payload }) => ({
    ...state,
    pending: false,
    error: payload,
  })),

  on(fromActions.createOrganization, (state, { payload }) => ({
    ...state,
    createPending: true,
    createError: undefined,
    created: undefined,
  })),
  on(fromActions.createOrganizationSuccess, (state, { payload }) =>
    myOrganizationAdapter.upsertOne(payload || [], {
      ...state,
      createPending: false,
      created: payload,
    })
  ),
  on(fromActions.createOrganizationFailure, (state, { payload }) => ({
    ...state,
    createPending: false,
    createError: payload,
  }))
);

export function reducer(
  state: IMyOrganizationsState | undefined,
  action: Action
): IMyOrganizationsState {
  return myOrganizationReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  myOrganizationAdapter.getSelectors();

export const getMyOrganizationsPending = (state: IMyOrganizationsState) =>
  state.pending;
export const getMyOrganizationsError = (state: IMyOrganizationsState) =>
  state.error;

export const getMyOrganizationsCreatePending = (state: IMyOrganizationsState) =>
  state.createPending;
export const getMyOrganizationsCreateError = (state: IMyOrganizationsState) =>
  state.createError;
export const getMyOrganizationsCreated = (state: IMyOrganizationsState) =>
  state.created;
