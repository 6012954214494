import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { query } from 'src/app/utils/rxjs-operators';
import * as fromGQL from 'src/generated/graphql';
import * as fromTypes from 'src/types';

@Injectable()
export class SystemLanguagesService {
  constructor(private systemLanguagesGQL: fromGQL.GetSystemLanguagesGQL) {}

  loadSystemLanguages(): Observable<fromTypes.LanguageResponse[]> {
    return this.systemLanguagesGQL.fetch().pipe(query('getSystemLanguages'));
  }
}
