import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TokenInterceptor } from './token.interceptor';

function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.addLangs(['US', 'EG', 'DE', 'FR']);
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

export const INTERCEPTORS_PROVIDERS = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [TranslateService],
    multi: true,
  },
];
