import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import * as fromStore from '../store';
import * as fromActions from '../store/actions';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    public auth: AuthService,
    private store: Store<fromStore.IRootState>
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken = this.auth.getAccessToken();
    const refreshToken = this.auth.getRefreshToken();

    if (accessToken && request.body?.operationName !== 'refreshToken') {
      request = this.addToken(request, accessToken);
    }
    if (refreshToken && request.body?.operationName === 'refreshToken') {
      request = this.addToken(request, refreshToken);
    }
    return next.handle(request).pipe(
      map((res) => {
        let needToAuthenticate = false;
        if (
          res.type === HttpEventType.Response &&
          res.status === 200 &&
          res.body &&
          Array.isArray(res.body.errors)
        ) {
          const errors = res.body.errors as any[];
          needToAuthenticate = !!errors.find(
            (e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED'
          );
          if (this.auth.getRefreshToken() && needToAuthenticate) {
            throw new Error(
              res.body?.errors?.[0]?.extensions?.exception?.message
            );
          }
        }
        return res;
      }),
      catchError((err) => {
        // Check if the request is for the REST API server
        if (request.url.startsWith(environment.aiServer)) {
          // Handle 401 Unauthorized specifically for the REST API server
          if (err.status === 401) {
            // Here, handle the 401 error, possibly by refreshing the token
            // or redirecting the user to the login page
            return this.handle401Error(request, next);
          } else {
            return throwError(err);
          }
        }

        // Handle GraphQL errors or other errors as before
        if (err instanceof Error) {
          return this.handle401Error(request, next);
        } else {
          return throwError(err);
        }
      })
    );
  }

  private addToken(
    request: HttpRequest<any>,
    token: string | null
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }

  /**
   * Handle refresh token
   *
   * @private
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof TokenInterceptor
   */
  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject.next(null);

      return this.auth.refreshToken().pipe(
        concatMap((data) => {
          this.isRefreshing = false;
          this.accessTokenSubject.next(data.accessToken);
          return next.handle(this.addToken(request, data.accessToken));
        }),
        catchError((err) => {
          // console.log('{throw error interceptor logout}', err);
          // const skipLogout = !!err?.error?.errors?.find(
          //   (e: any) => e.extensions && e.extensions.code === 'BAD_USER_INPUT'
          // );
          if (err instanceof Error) {
            this.store.dispatch(fromActions.logoutSuccess());
          }

          return throwError(err);
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        concatMap((accessToken) => {
          console.log('{concatMap if refreshing}', request);
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }
}
