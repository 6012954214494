import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromTimeZonesReducer from '../reducers/timezones.reducer';

export const getTimeZonesSelectAll = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.selectAll
);

export const getTimeZonesSelectEntities = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.selectEntities
);

export const getTimeZonesSelectIds = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.selectIds
);

export const getTimeZonesSelectTotal = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.selectTotal
);

export const getTimeZonesPending = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.getTimeZonesPending
);

export const getTimeZonesError = createSelector(
  fromFeature.getTimeZonesState,
  fromTimeZonesReducer.getTimeZonesError
);
