import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import { IRootState } from '.';
import * as fromActions from '../actions';
import { initialState as initialAuthState } from './auth.reducer';
import { initialState as initialSystemLanguagesState } from './system-languages.reducer';
import { initialState as initialTimeZonesState } from './timezones.reducer';
import { initialState as initialMyOrganizationsState } from './my-organizations.reducer';
import { initialState as initialOrganizationState } from './organization.reducer';

function clearState(reducer: ActionReducer<IRootState>) {
  return function (state: IRootState | undefined, action: Action) {
    if (action.type === fromActions.logoutSuccess.type) {
      const navigationId: number = state?.router?.navigationId || 0;
      state = {
        auth: initialAuthState,
        systemLanguages: state?.systemLanguages || initialSystemLanguagesState,
        router: {
          navigationId: navigationId + 1,
          state: {
            url: '/',
            queryParams: {},
            params: {},
          },
        },
        timeZones: initialTimeZonesState,
        myOrganizations: initialMyOrganizationsState,
        organization: initialOrganizationState,
      };
    }
    return reducer(state, action);
  };
}

function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    // console.log('state before: ', state);
    // console.log('action', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<IRootState>[] = !environment.production
  ? [logger, clearState]
  : [clearState];
