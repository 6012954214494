import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { LANGUAGES } from 'src/app/constants';
import * as fromTypes from 'src/types';

import * as fromActions from '../actions';

export const systemLanguageAdapter =
  createEntityAdapter<fromTypes.LanguageResponse>({
    selectId: (lang) => lang?.code,
  });

export interface ISystemLanguagesState
  extends EntityState<fromTypes.LanguageResponse> {
  pending: boolean;
  error: Error | undefined;
}

export const initialState: ISystemLanguagesState =
  systemLanguageAdapter.getInitialState({
    pending: false,
    error: undefined,
  });

export const systemLanguagesReducer = createReducer(
  initialState,

  // Handle loadSystemLanguages actions
  on(fromActions.loadSystemLanguages, (state) => {
    return systemLanguageAdapter.setAll([], {
      ...state,
      pending: true,
      error: undefined,
    });
  }),
  on(fromActions.loadSystemLanguagesSuccess, (state, { payload }) =>
    systemLanguageAdapter.setAll([...LANGUAGES, ...payload] || [], {
      ...state,
      pending: false,
    })
  ),
  on(fromActions.loadSystemLanguagesFailure, (state, { payload }) => ({
    ...state,
    pending: false,
    error: payload,
  }))
);

export function reducer(
  state: ISystemLanguagesState | undefined,
  action: Action
): ISystemLanguagesState {
  return systemLanguagesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  systemLanguageAdapter.getSelectors();

export const getSystemLanguagesPending = (state: ISystemLanguagesState) =>
  state.pending;
export const getSystemLanguagesError = (state: ISystemLanguagesState) =>
  state.error;
