import { createAction, props } from '@ngrx/store';
import * as fromTypes from 'src/types';

/**************************** Queries ****************************/
/** loadOrganization Actions */
export const loadOrganization = createAction(
  '[App] load organization',
  props<{ payload: { organizationId: string } }>()
);
export const loadOrganizationSuccess = createAction(
  '[App] load organization success',
  props<{ payload: fromTypes.MyOrganizationWithJobTitleResponse }>()
);
export const loadOrganizationFailure = createAction(
  '[App] load organization failure',
  props<{ payload: Error }>()
);

/** loadUniqueDomain Actions (add it in my-organization) */
export const loadUniqueDomain = createAction(
  '[App] load unique domain',
  props<{ payload: { organizationName: string } }>()
);
export const loadUniqueDomainSuccess = createAction(
  '[App] load unique domain success',
  props<{ payload: string }>()
);
export const loadUniqueDomainFailure = createAction(
  '[App] load unique domain failure',
  props<{ payload: Error }>()
);

/**************************** Mutations ****************************/
/** updateOrganization Actions */
export const updateOrganization = createAction(
  '[App] update organization',
  props<{ payload: fromTypes.MutationUpdateOrganizationArgs }>()
);
export const updateOrganizationSuccess = createAction(
  '[App] update organization success',
  props<{ payload: fromTypes.MyOrganizationWithJobTitleResponse }>()
);
export const updateOrganizationFailure = createAction(
  '[App] update organization failure',
  props<{ payload: Error }>()
);
