import { FetchResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Mutation } from 'src/types';

type Keys = keyof Mutation;
// type ValueOf<T> = Mutation[keyof Mutation];
// type KeyQuery = Omit<Keys, '__typename'>;
// type ValueOfKeys = { [keys: string]: ValueOf<Keys> }; // string | number

// const x: ValueOfKeys = {
//   cloneCompetenciesToSurvey: {
//     __typename: 'SuccessResponse',
//   message: 'String',
//   success: true,
//   },
//   x: {
//     success: true,
//     _id: 'ss'
//   }
// };

// do it later
export const mutation = (key: Keys) =>
  function <T>(source: Observable<FetchResult<T>>) {
    return source.pipe(
      // filter((result) => !!(result?.data as any)?.[key]),
      map((result) => {
        if ((result?.data as any)?.[key]) {
          return (result?.data as any)?.[key];
        } else {
          throw new Error(result.errors?.map((err) => err.message).join(', '));
        }
      })
    );
    // filter((result) => !!result?.data?.updateMyProfile),
    //  map((result) => result.data?.updateMyProfile!)
  };
