import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';

export const getParamsByRouter = createSelector(
  fromRoot.getRouterState,
  (router: any) => {
    return router && router.state && router.state.params;
  }
);

export const getQueryParamsByRouter = createSelector(
  fromRoot.getRouterState,
  (router: any) => router && router.state.queryParams
);

// selector used to get query param when user reset password
export const getQueryParamsInvitationToken = createSelector(
  getQueryParamsByRouter,
  (queryParam) => queryParam && queryParam.invitationToken
);

export const getParamOrganizationId = createSelector(
  getParamsByRouter,
  (params) => params?.organizationId
);
