import { Action, createReducer, on } from '@ngrx/store';
import * as fromTypes from 'src/types';

import * as fromActions from '../actions';

export interface IOrganizationState {
  pending: boolean;
  error: Error | undefined;
  detail: fromTypes.MyOrganizationWithJobTitleResponse | undefined;

  domainPending: boolean;
  domainError: Error | undefined;
  domain: string | undefined;

  updatePending: boolean;
  updateError: Error | undefined;
}

export const initialState: IOrganizationState = {
  pending: false,
  error: undefined,
  detail: undefined,

  domainPending: false,
  domainError: undefined,
  domain: undefined,

  updatePending: false,
  updateError: undefined,
};

export const organizationReducer = createReducer(
  initialState,

  on(fromActions.updateOrganization, (state, { payload }) => ({
    ...state,
    updatePending: true,
    updateError: undefined,
  })),
  on(fromActions.updateOrganizationSuccess, (state, { payload }) => ({
    ...state,
    updatePending: false,
    detail: payload,
  })),
  on(fromActions.updateOrganizationFailure, (state, { payload }) => ({
    ...state,
    updatePending: false,
    updateError: payload,
  })),

  // Handle loadUniqueDomain actions
  on(fromActions.loadUniqueDomain, (state) => ({
    ...state,
    domainPending: true,
    domainError: undefined,
    domain: undefined,
  })),
  on(fromActions.loadUniqueDomainSuccess, (state, { payload }) => ({
    ...state,
    domainPending: false,
    domain: payload,
  })),
  on(fromActions.loadUniqueDomainFailure, (state, { payload }) => ({
    ...state,
    domainPending: false,
    domainError: payload,
  })),

  // Handle loadOrganization actions
  on(fromActions.loadOrganization, (state) => ({
    ...state,
    pending: true,
    error: undefined,
    detail: undefined,
  })),
  on(fromActions.loadOrganizationSuccess, (state, { payload }) => ({
    ...state,
    pending: false,
    detail: payload,
  })),
  on(fromActions.loadOrganizationFailure, (state, { payload }) => ({
    ...state,
    pending: false,
    error: payload,
  }))
);

export function reducer(
  state: IOrganizationState | undefined,
  action: Action
): IOrganizationState {
  return organizationReducer(state, action);
}

export const getOrganizationUpdatePending = (state: IOrganizationState) =>
  state.updatePending;
export const getOrganizationUpdateError = (state: IOrganizationState) =>
  state.updateError;

export const getOrganizationPending = (state: IOrganizationState) =>
  state.pending;
export const getOrganizationError = (state: IOrganizationState) => state.error;

export const getOrganizationDomainPending = (state: IOrganizationState) =>
  state.domainPending;
export const getOrganizationDomainError = (state: IOrganizationState) =>
  state.domainError;
export const getOrganizationDomain = (state: IOrganizationState) =>
  state.domain;

export const getOrganizationDetail = (state: IOrganizationState) =>
  state.detail;
