import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as fromServices from '../services';
import { RedirectService } from '../services/redirect.service';
import * as fromStore from '../store';

/**
 * check if user authenticated navigate user to the following url in queryParams
 * pass it if user isn't authenticated
 * @export
 * @class CloseAuthGuardService
 * @implements {CanActivate}
 */
@Injectable({
  providedIn: 'root',
})
export class CloseAuthGuardService implements CanActivate {
  constructor(
    private auth: fromServices.AuthService,
    private redirectService: RedirectService,
    private store: Store<fromStore.IRootState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getAuthLogoutPending),
      filter((pending) => !pending),
      map((pending) => {
        if (this.auth.isAuthenticated()) {
          this.redirectService.redirect(route.queryParams.redirectURL);
          return false;
        }
        return true;
      })
    );
  }
}
