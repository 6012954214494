import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { query } from 'src/app/utils/rxjs-operators';
import * as fromGQL from 'src/generated/graphql';

@Injectable()
export class TimeZonesService {
  constructor(private fromTimeZonesGQL: fromGQL.TimezonesGQL) {}

  loadTimeZones(): Observable<string[]> {
    return this.fromTimeZonesGQL.fetch().pipe(query('timezones'));
  }
}
