<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form__group">
    <mat-label class="form__group__label" for="email">Email Address</mat-label>
    <div class="input-wrapper with-prefix">
      <i class="icon prefix ph-at" [ngClass]="{ 'has-value': emailValue }"></i>
      <input
        type="text"
        placeholder="Enter your email"
        formControlName="email"
        id="email"
        aria-label="add Email to forget password"
      />
    </div>

    <mat-error *ngIf="emailRequired"> email is required</mat-error>
    <mat-error *ngIf="emailPatternInvalid">Invalid email address</mat-error>
  </div>

  <button
    mat-flat-button
    color="primary"
    class="form__button"
    [type]="forgetPasswordPending ? 'button' : 'submit'"
    role="button"
    aria-label="Reset Password"
    [disabled]="forgetPasswordPending"
  >
    {{ forgetPasswordPending ? "resetting..." : "Reset Password " }}
  </button>
</form>
