import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signinPending$: Observable<boolean>;

  constructor(private store: Store<fromStore.IRootState>) {}

  ngOnInit(): void {
    this.signinPending$ = this.store.pipe(
      select(fromStore.getAuthSigninPending)
    );
  }

  onSubmit(credentials: { email: string; password: string }): void {
    this.store.dispatch(fromActions.signin({ payload: credentials }));
  }
}
