import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromAuthReducer from '../reducers/auth.reducer';

export const getAuthTempEmail = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getTempEmail
);
export const getAuthUserId = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUserId
);
export const getAuthVerificationToken = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerificationToken
);
export const getAuthIsVerified = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getIsVerified
);
// Signin
export const getAuthSigninPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSigninPending
);

export const getAuthSigninError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSigninError
);

// Signup
export const getAuthSignupPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSignupPending
);

export const getAuthSignupError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getSignupError
);

//user
export const getAuthUserPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUserPending
);

export const getAuthUserError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getUserError
);

export const getAuthCurrentUser = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getCurrentUser
);

export const getAuthCurrentUserId = createSelector(
  getAuthCurrentUser,
  (currentUser) => currentUser && currentUser._id
);

export const getAuthCurrentIsSuperAdmin = createSelector(
  getAuthCurrentUser,
  (currentUser) => !!currentUser && !!currentUser.isSuperAdmin
);

// Forget password
export const getAuthForgetPasswordPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getForgetPasswordPending
);

export const getAuthForgetPasswordError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getForgetPasswordError
);

// Reset password
export const getAuthResetPasswordPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResetPasswordPending
);

export const getAuthResetPasswordError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResetPasswordError
);

// new selectors
// Logout
export const getAuthLogoutPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLogoutPending
);

export const getAuthLogoutError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getLogoutError
);

// ResendVerification
export const getAuthResendVerificationPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResendVerificationPending
);

export const getAuthResendVerificationError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getResendVerificationError
);

// Verify Account
export const getAuthVerifyPending = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyPending
);

export const getAuthVerifyError = createSelector(
  fromRoot.getAuthState,
  fromAuthReducer.getVerifyError
);

// 'SENDING_AGAIN' |
// 'SIGNUP' |
// 'SIGNIN' |
// 'VERIFY' |
// 'VERIFIED' |
// 'FORGOT_PASSWORD' |
// 'RESET_PASSWORD';
