import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';
import * as fromTypes from 'src/types';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupPending$: Observable<boolean>;
  constructor(private store: Store<fromStore.IRootState>) {}

  ngOnInit(): void {
    this.signupPending$ = this.store.pipe(
      select(fromStore.getAuthSignupPending)
    );
  }

  onSubmit(user: fromTypes.SignupInput): void {
    // console.log(user);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log(timezone);
      this.store.dispatch(
        fromActions.signup({ payload: { ...user, timezone } })
      );
    } catch (error) {
      // console.log(error);
      this.store.dispatch(fromActions.signup({ payload: user }));
    }
  }
}
