import { AuthEffects } from './auth.effects';
import { MyOrganizationsEffects } from './my-organizations.effects';
import { OrganizationEffects } from './organization.effects';
import { SystemLanguagesEffects } from './system-languages.effects';
import { TimeZonesEffects } from './timezones.effects';

export const EFFECTS = [
  AuthEffects,
  SystemLanguagesEffects,
  TimeZonesEffects,
  MyOrganizationsEffects,
  OrganizationEffects,
];

export * from './auth.effects';
export * from './system-languages.effects';
export * from './timezones.effects';
export * from './my-organizations.effects';
export * from './organization.effects';
