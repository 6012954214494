import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

import { SnackBarComponent } from '../components';

export class SnackBarMessage {
  message: string;
  type: 'SUCCESS' | 'FAILURE' | 'WARNING' = 'SUCCESS';
  duration: number | null = 3000;
  direction: 'ltr' | 'rtl' = 'ltr';
}

@Injectable()
@UntilDestroy()
export class SnackBarService {
  private snackBarRef: MatSnackBarRef<SnackBarComponent>;
  private msgQueue: SnackBarMessage[] = [];
  private isInstanceVisible = false;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  open(
    message: string,
    type: 'SUCCESS' | 'FAILURE' | 'WARNING',
    duration: number | null = 3000,
    direction: 'ltr' | 'rtl' = 'ltr'
  ) {
    let sbMessage = new SnackBarMessage();
    sbMessage.message = message;
    sbMessage.type = type;
    sbMessage.duration = duration;
    sbMessage.direction = direction;

    this.msgQueue.push(sbMessage);
    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  translate(
    message: string,
    type: 'SUCCESS' | 'FAILURE' | 'WARNING',
    duration: number | null = 3000,
    direction: 'ltr' | 'rtl' = 'ltr',
    extraMessage?: string
  ) {
    this.translateService.get(message).subscribe((resMessage: string) => {
      this.open(
        resMessage + `${extraMessage ? ', ' + extraMessage : ''}`,
        type,
        duration,
        direction
      );
    });
  }

  showNext() {
    if (this.msgQueue.length === 0) {
      return;
    }

    const currentMessage = this.msgQueue.shift();
    this.isInstanceVisible = true;
    this.snackBarRef = this.snackBar.openFromComponent(SnackBarComponent, {
      data: currentMessage,
      panelClass:
        currentMessage?.direction === 'rtl'
          ? [`${currentMessage?.type?.toLowerCase()}-snackbar`, 'ar']
          : `${currentMessage?.type?.toLowerCase()}-snackbar`,
      duration: currentMessage?.duration || undefined,
      direction: currentMessage?.direction || 'ltr',
    });
    this.snackBarRef
      .afterDismissed()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isInstanceVisible = false;
        this.showNext();
      });
  }

  dismissAll() {
    this.msgQueue = [];
    this.snackBar.dismiss();
  }
}
