<div class="auth-wrapper">
  <div class="auth">
    <img
      src="assets/hub/logo.svg"
      class="auth__logo"
      role="img"
      alt="Assess Talent Management Logo"
      aria-label="Assess Talent Management Logo"
    />

    <p class="auth__title">
      Welcome to assess,<br />Join and enjoy creating surveys and assessments.
    </p>
    <!-- <div class="auth__tab">
      <a
        class="auth__tab__button"
        [routerLink]="['/auth/login']"
        queryParamsHandling="merge"
      >
        Login
      </a>
      <a class="auth__tab__button auth__tab__button--selected"
        >Create Account</a
      >
    </div> -->

    <app-signup-form
      [signupPending]="(signupPending$ | async) ? true : false"
      (submitted)="onSubmit($event)"
    ></app-signup-form>
  </div>
</div>
