<div class="auth" *ngIf="resendPending$ | async; else resent">
  <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
    <img
      src="assets/hub/logo.svg"
      role="img"
      alt="Assess Talent Management Logo"
      aria-label="Assess Talent Management Logo"
    />
    <!-- <mat-progress-spinner
      [mode]="'indeterminate'"
      [diameter]="50"
      fxFlexAlign="center"
    >
    </mat-progress-spinner> -->
    <app-lottie
      src="/assets/lottie/loading-indicator.json"
      maxWidth="300px"
      [loop]="false"
    ></app-lottie>
  </div>
</div>

<ng-template #resent>
  <button type="button" class="back" (click)="openDialog()">
    <i class="ph-arrow-left"></i>
  </button>
  <div class="auth-wrapper">
    <div class="auth">
      <img
        src="assets/hub/logo.svg"
        class="auth__logo"
        role="img"
        alt="Assess Talent Management Logo"
        aria-label="Assess Talent Management Logo"
      />
      <p *ngIf="userEmail$ | async as email" class="title">
        We’ve sent a verification code to your email. <br />
        Please check your email “{{ email }}”
      </p>

      <app-verify-form
        (submitted)="onSubmit($event)"
        [verifyPending]="(verifyPending$ | async) ? true : false"
        [serverError]="verifyError$ | async"
      ></app-verify-form>
      <p class="form__notice">
        I can’t find it?
        <a
          role="button"
          tabindex="0"
          (click)="resendVerification()"
          [ngClass]="{ disabled: counter < 60 }"
          #resendButton
        >
          Send again
        </a>
        after 00:{{ counter }}
      </p>
    </div>
  </div>
</ng-template>
