import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Config } from 'ng-otp-input/lib/models/config';
import { debounceTime, filter } from 'rxjs/operators';
import { CustomValidators } from 'src/app/utils/custom-validators';
import * as fromTypes from 'src/types';

@UntilDestroy()
@Component({
  selector: 'app-verify-form',
  templateUrl: './verify-form.component.html',
  styleUrls: ['./verify-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyFormComponent implements OnInit, OnChanges {
  form: FormGroup;
  isSubmitted = false;
  @Output() submitted = new EventEmitter<number>();
  @Input() verifyPending: boolean;
  @Input() serverError: fromTypes.Maybe<Error | undefined>;

  config: Config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '44px',
      height: '44px',
    },
  };

  get verificationCodeControl() {
    return this.form && this.form.get('verificationCode');
  }

  get verificationCodeRequired(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('verificationCode')?.invalid &&
      !!this.form.get('verificationCode')?.hasError('required')
    );
  }

  get verificationCodeServerError(): boolean {
    return (
      this.isSubmitted &&
      this.form &&
      !!this.form.get('verificationCode')?.invalid &&
      !!this.form.get('verificationCode')?.hasError('serverError') &&
      !this.form.get('verificationCode')?.hasError('required')
    );
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      verificationCode: [, [CustomValidators.required]],
    });

    this.verificationCodeControl?.valueChanges
      .pipe(
        filter((data: number) => data.toString().length === 6),
        debounceTime(500),
        untilDestroyed(this)
      )
      .subscribe((data) => this.onSubmit());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverError && changes.serverError.currentValue) {
      // if (this.serverError?.name === 'verificationCode') {
      this.form.get('verificationCode')?.setErrors({ serverError: true });
      // }
    }
  }

  onSubmit(): void {
    this.isSubmitted = true;
    const { value, valid } = this.form;

    if (valid) {
      this.submitted.emit(value?.verificationCode);
    }
  }

  onOtpChange($event: string) {
    this.form.get('verificationCode')?.patchValue(+$event);
  }
}
