import {
  AbstractControl,
  FormArray,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

/** This class for trimming form controls (this is the main difference from Angular Validators)
 *
 * this file used as a helper in any project
 * @export
 * @class CustomValidators
 */
export class CustomValidators {
  public static required(control: FormControl): { required: true } | null {
    if (
      !control.value ||
      (typeof control.value === 'string' && !control.value.trim())
    ) {
      return {
        required: true,
      };
    }

    return null;
  }

  public static requiredNumberIncludeZero(control: FormControl) {
    if (control.value === 0) {
      return null;
    }

    if (
      !control.value ||
      (typeof control.value === 'string' && !control.value.trim())
    ) {
      return {
        required: true,
      };
    }

    return null;
  }

  public static minLength(length: number): (control: FormControl) => {
    minlength: boolean;
  } | null {
    return (control: FormControl) => {
      if (
        !control.value ||
        (typeof control.value === 'string' &&
          control.value.trim().length < length)
      ) {
        return {
          minlength: true,
        };
      }

      return null;
    };
  }

  public static maxLength(length: number): (control: FormControl) => {
    maxlength: boolean;
  } | null {
    return (control: FormControl) => {
      if (
        control.value &&
        typeof control.value === 'string' &&
        control.value.trim().length > length
      ) {
        return {
          maxlength: true,
        };
      }

      return null;
    };
  }

  public static minLengthArray(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length >= min) {
        return null;
      }

      return { minLengthArray: { valid: false, invalid: true } };
    };
  }

  public static maxLengthArray(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length <= max) {
        return null;
      }

      return { maxLengthArray: { valid: false, invalid: true } };
    };
  }

  public static lengthArray(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value.length === length) {
        return null;
      }

      return { lengthArray: { valid: false, invalid: true } };
    };
  }

  public static existsInArr(arr: string[]) {
    return (control: FormControl) => {
      const filteredArr = arr.map((item) => item.trim().toLowerCase());
      if (
        !control.value ||
        (typeof control.value === 'string' &&
          filteredArr.includes(control.value.trim().toLowerCase()))
      ) {
        return {
          existsInArr: true,
        };
      }

      return null;
    };
  }

  public static notZero(control: FormControl): { notZero: boolean } | null {
    return control.value === 0 ? { notZero: true } : null;
  }

  public static isPositive(control: FormControl): { positive: boolean } | null {
    return control.value <= 0 ? { positive: true } : null;
  }

  public static notEqual(equalsTo: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === equalsTo) {
        return null;
      }

      return { notEqual: { valid: false, invalid: true } };
    };
  }

  public static isObject(control: FormControl): { object: true } | null {
    if (typeof control.value === 'object') {
      return null;
    }

    return { object: true };
  }

  public static hasDuplicate(
    key_form: string,
    catchLengthStartFrom = 0
  ): ValidatorFn {
    return (formArray: AbstractControl): { [key: string]: any } | null => {
      if ((formArray as FormArray)?.length) {
        for (var i = 0; i < (formArray as FormArray)?.length; i++) {
          let errors: { duplicate?: boolean | null } =
            ((formArray as FormArray)?.at(i).get(key_form)?.errors as Object) ||
            {};
          delete errors['duplicate'];
          (formArray as FormArray)
            ?.at(i)
            .get(key_form)
            ?.setErrors(
              Object.keys(errors as ValidationErrors)?.length
                ? (errors as ValidationErrors)
                : null
            );
        }
      }

      let dict: { [key: string]: number[] } = {};

      (formArray as FormArray).value.forEach(
        (item: { [key: string]: string }, index: number) => {
          const val = item[key_form]?.trim()?.toLowerCase();
          if (val.length >= catchLengthStartFrom) {
            dict[val] = dict[val] || [];
            dict[val].push(index);
          }
        }
      );
      let duplicates: number[] = [];
      for (var key in dict) {
        if (dict[key].length > 1) duplicates = duplicates.concat(dict[key]);
      }

      for (const index of duplicates) {
        (formArray as FormArray)
          .at(+index)
          .get(key_form)
          ?.setErrors({
            ...((formArray as FormArray).at(+index).get(key_form)?.errors ||
              {}),
            duplicate: true,
          });
      }

      return duplicates.length > 0 ? { error: 'Has Duplicate !!!' } : null;
    };
  }

  static numberValidator(number: AbstractControl): any {
    const NUMBER_REGEXP = /^(-)?[0-9]*$/;
    if (!number.value || NUMBER_REGEXP.test(number.value)) {
      return null;
    }
    return { number: true };
  }

  static phoneValidator(number: AbstractControl): any {
    const PHONE_REGEXP = /^(\+[0-9]{1,3}[- ]?)?[0-9]{10,11}$/;
    if (!number.value || PHONE_REGEXP.test(number.value)) {
      return null;
    }
    return { phone: true };
  }
}
