export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AcceptJoinRequestInput = {
  joinRequestId: Scalars['String'];
  roleId: Scalars['String'];
};

export type AcmCaseStudyFileResponse = {
  __typename?: 'AcmCaseStudyFileResponse';
  _id: Scalars['String'];
  accessibleTo: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  localeCode: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AcmCaseStudyFilesResponse = {
  __typename?: 'AcmCaseStudyFilesResponse';
  docs: Array<AcmCaseStudyFileResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type AcmCompetenciesResponse = {
  __typename?: 'AcmCompetenciesResponse';
  docs: Array<AcmCompetencyResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type AcmCompetencyResponse = {
  __typename?: 'AcmCompetencyResponse';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type AcmProjectCompetenciesResponse = {
  __typename?: 'AcmProjectCompetenciesResponse';
  docs: Array<AcmProjectCompetencyResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type AcmProjectCompetencyExerciseResponse = {
  __typename?: 'AcmProjectCompetencyExerciseResponse';
  _id: Scalars['String'];
  caseStudyId: Scalars['String'];
  kpiIds?: Maybe<Array<Scalars['String']>>;
  weightPercentage: Scalars['Float'];
};

export type AcmProjectCompetencyResponse = {
  __typename?: 'AcmProjectCompetencyResponse';
  _id: Scalars['String'];
  competencies?: Maybe<Array<AcmCompetencyResponse>>;
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Scalars['String']>;
  exercises?: Maybe<Array<AcmProjectCompetencyExerciseResponse>>;
  name?: Maybe<Scalars['String']>;
};

export type AcmProjectParticipantAssessorResponse = {
  __typename?: 'AcmProjectParticipantAssessorResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AcmProjectParticipantResponse = {
  __typename?: 'AcmProjectParticipantResponse';
  _id: Scalars['String'];
  assessors?: Maybe<Array<AcmProjectParticipantAssessorResponse>>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AcmProjectResponse = {
  __typename?: 'AcmProjectResponse';
  _id: Scalars['String'];
  companyImage?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  numOfDays: Scalars['Float'];
  purpose: Scalars['String'];
  scoringScale?: Maybe<Array<AcmProjectScoringScaleResponse>>;
};

export type AcmProjectScoringScaleResponse = {
  __typename?: 'AcmProjectScoringScaleResponse';
  color: Scalars['String'];
  from: Scalars['Float'];
  name: Scalars['String'];
  to: Scalars['Float'];
};

export type AcmProjectsResponse = {
  __typename?: 'AcmProjectsResponse';
  docs: Array<AcmProjectResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type AddUnitsInput = {
  organizationId: Scalars['String'];
  units: Scalars['Float'];
};

export type AdsAssessmentResponse = {
  __typename?: 'AdsAssessmentResponse';
  _id: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AdsAssessmentsResponse = {
  __typename?: 'AdsAssessmentsResponse';
  docs: Array<AdsAssessmentResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type AnswerCompetencyItemInput = {
  competencyId: Scalars['String'];
  competencyItemId: Scalars['String'];
  responseId: Scalars['String'];
};

export type AnswerCompetencyItemResponse = {
  __typename?: 'AnswerCompetencyItemResponse';
  competencyItemId: Scalars['String'];
  responseId: Scalars['String'];
  subItem?: Maybe<SessionSubItemResponse>;
};

export type AnswerStdSessionDemographicInput = {
  answerId?: InputMaybe<Scalars['String']>;
  answerIds?: InputMaybe<Array<Scalars['String']>>;
  answerText?: InputMaybe<Scalars['String']>;
  demographicId: Scalars['String'];
};

export type AnswerStdSessionDemographicResponse = {
  __typename?: 'AnswerStdSessionDemographicResponse';
  answerId?: Maybe<Scalars['String']>;
  answerIds?: Maybe<Array<Scalars['String']>>;
  answerText?: Maybe<Scalars['String']>;
  demographicId: Scalars['String'];
};

export type AnswerStdSessionOpenEndedQuestionInput = {
  openEndedResponse?: InputMaybe<Scalars['String']>;
  stdPageItemId: Scalars['String'];
};

export type AnswerStdSessionOpenEndedQuestionResponse = {
  __typename?: 'AnswerStdSessionOpenEndedQuestionResponse';
  openEndedResponse?: Maybe<Scalars['String']>;
  stdPageItemId: Scalars['String'];
};

export type AnswerStdSessionPageItemQuestionInput = {
  answerId: Scalars['String'];
  questionId: Scalars['String'];
  stdPageItemId: Scalars['String'];
};

export type AnswerStdSessionPageItemQuestionResponse = {
  __typename?: 'AnswerStdSessionPageItemQuestionResponse';
  answerId?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
  stdPageItemId: Scalars['String'];
};

export type AnswerSubItemInput = {
  competencyItemId: Scalars['String'];
  responseId: Scalars['String'];
  subItemId: Scalars['String'];
};

export type AnswerSubItemResponse = {
  __typename?: 'AnswerSubItemResponse';
  responseId: Scalars['String'];
  subItemId: Scalars['String'];
};

export type AssignedAssessmentResponse = {
  __typename?: 'AssignedAssessmentResponse';
  _id: Scalars['String'];
  assessmentLink?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  productId: Scalars['String'];
  productImage?: Maybe<Scalars['String']>;
  productName: Scalars['String'];
};

export type CaseStudiesResponse = {
  __typename?: 'CaseStudiesResponse';
  docs: Array<CaseStudyResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type CaseStudyKpiResponse = {
  __typename?: 'CaseStudyKpiResponse';
  _id: Scalars['String'];
  kpi: KpiResponse;
  order: Scalars['String'];
};

export type CaseStudyResponse = {
  __typename?: 'CaseStudyResponse';
  _id: Scalars['String'];
  competencyIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  maxCandidates?: Maybe<Scalars['Float']>;
  minCandidates?: Maybe<Scalars['Float']>;
  performingDuration?: Maybe<Scalars['String']>;
  preparationDuration?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
};

export type CheckStdSessionResponse = {
  __typename?: 'CheckStdSessionResponse';
  allowBack?: Maybe<Scalars['Boolean']>;
  demographics?: Maybe<Array<StdSessionDemographicResponse>>;
  infoPageData?: Maybe<StdSessionInfoPageDataResponse>;
  mustAnswerAll?: Maybe<Scalars['Boolean']>;
  organizationName: Scalars['String'];
  responsesLayout: Scalars['String'];
  selectLocaleData?: Maybe<StdSessionSelectLocaleDataResponse>;
  selectedLocaleCode?: Maybe<Scalars['String']>;
  stdPageData?: Maybe<StdSessionPageDataResponse>;
  surveyHorizontalImage?: Maybe<Scalars['String']>;
  surveyName?: Maybe<Scalars['String']>;
  surveyPrimaryColor?: Maybe<Scalars['String']>;
  surveyVerticalImage?: Maybe<Scalars['String']>;
  toRender: Scalars['String'];
};

export type CheckSurveySessionResponse = {
  __typename?: 'CheckSurveySessionResponse';
  competencyData?: Maybe<SurveySessionCompetencyDataResponse>;
  infoPageData?: Maybe<SurveySessionInfoPageDataResponse>;
  organizationName: Scalars['String'];
  responsesLayout: Scalars['String'];
  selectLocaleData?: Maybe<SurveySessionSelectLocaleDataResponse>;
  selectedLocaleCode?: Maybe<Scalars['String']>;
  selfRaterName: Scalars['String'];
  surveyHorizontalImage?: Maybe<Scalars['String']>;
  surveyPrimaryColor?: Maybe<Scalars['String']>;
  surveyVerticalImage?: Maybe<Scalars['String']>;
  toRender: Scalars['String'];
};

export type CloneCompetencyInput = {
  _id?: InputMaybe<Scalars['String']>;
  order: Scalars['String'];
  originalCompetencyId: Scalars['String'];
};

export type CommentCompetencyInput = {
  comment: Scalars['String'];
  competencyId: Scalars['String'];
};

export type CommentCompetencyResponse = {
  __typename?: 'CommentCompetencyResponse';
  comment: Scalars['String'];
  competencyId: Scalars['String'];
};

export type CompetenciesArgs = {
  localeCodes: Array<Scalars['String']>;
};

export type Competency360ReportResponse = {
  __typename?: 'Competency360ReportResponse';
  col: Scalars['Float'];
  definition?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  narratives: Array<Narrative360ReportResponse>;
  participantsSkillAndSignificant: Array<ParticipantsSkillAndSignificant>;
  row: Scalars['Float'];
  selfRaterSignificantMean: Scalars['Float'];
  selfRaterSkillMean: Scalars['Float'];
  totalSelfRaterAgreement?: Maybe<Scalars['String']>;
  totalSelfRaterSignificantMean: Scalars['Float'];
  totalSelfRaterSkillMean: Scalars['Float'];
};

export type CompetencyComments360ReportResponse = {
  __typename?: 'CompetencyComments360ReportResponse';
  name: Scalars['String'];
  participants?: Maybe<Array<ParticipantsComments>>;
};

export type CompetencyGroupResponse = {
  __typename?: 'CompetencyGroupResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  definition: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type CompetencyGroupWithChildrenResponse = {
  __typename?: 'CompetencyGroupWithChildrenResponse';
  _id: Scalars['String'];
  competencies?: Maybe<Array<CompetencyResponse>>;
  competenciesCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  definition: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type CompetencyGroupsResponse = {
  __typename?: 'CompetencyGroupsResponse';
  docs: Array<CompetencyGroupWithChildrenResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type CompetencyItemResponse = {
  __typename?: 'CompetencyItemResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  measure?: Maybe<Scalars['String']>;
  order: Scalars['String'];
  questionTitle?: Maybe<Array<LocaleResponse>>;
  responseStatements?: Maybe<Array<CompetencyItemResponseStatement>>;
  responses?: Maybe<Array<CompetencyItemResponseResponse>>;
  responsesLogic?: Maybe<Array<CompetencyItemResponseLogicResponse>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompetencyItemResponseLogicResponse = {
  __typename?: 'CompetencyItemResponseLogicResponse';
  _id: Scalars['String'];
  data?: Maybe<CompetencySubItemResponse>;
  responseId: Scalars['String'];
  type: Scalars['String'];
};

export type CompetencyItemResponseResponse = {
  __typename?: 'CompetencyItemResponseResponse';
  _id: Scalars['String'];
  score: Scalars['Float'];
  text?: Maybe<Array<LocaleResponse>>;
};

export type CompetencyItemResponseStatement = {
  __typename?: 'CompetencyItemResponseStatement';
  _id: Scalars['String'];
  responseIds: Array<Scalars['String']>;
  text?: Maybe<Array<LocaleResponse>>;
  type: Scalars['String'];
};

export type CompetencyNarrativeResponse = {
  __typename?: 'CompetencyNarrativeResponse';
  text?: Maybe<Array<LocaleResponse>>;
  type: Scalars['String'];
};

export type CompetencyResponse = {
  __typename?: 'CompetencyResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Array<LocaleResponse>>;
  name?: Maybe<Array<LocaleResponse>>;
  narratives?: Maybe<Array<CompetencyNarrativeResponse>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompetencySubItemResponse = {
  __typename?: 'CompetencySubItemResponse';
  _id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  questionDefinition?: Maybe<Array<LocaleResponse>>;
  questionTitle?: Maybe<Array<LocaleResponse>>;
  responses?: Maybe<Array<CompetencySubItemResponseResponse>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompetencySubItemResponseResponse = {
  __typename?: 'CompetencySubItemResponseResponse';
  _id: Scalars['String'];
  score: Scalars['Float'];
  text?: Maybe<Array<LocaleResponse>>;
};

export type CompetencyWithItemsResponse = {
  __typename?: 'CompetencyWithItemsResponse';
  _id: Scalars['String'];
  competenciesItems?: Maybe<Array<CompetencyItemResponse>>;
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Array<LocaleResponse>>;
  name?: Maybe<Array<LocaleResponse>>;
  narratives?: Maybe<Array<CompetencyNarrativeResponse>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateAcmCaseStudyFileInput = {
  accessibleTo: Array<Scalars['String']>;
  localeCode: Scalars['String'];
};

export type CreateAcmCompetencyInput = {
  name: Scalars['String'];
};

export type CreateAcmProjectCompetencyInput = {
  definition?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateAcmProjectInput = {
  companyName: Scalars['String'];
  name: Scalars['String'];
  numOfDays: Scalars['Float'];
  purpose: Scalars['String'];
  scoringScale?: InputMaybe<Array<CreateAcmProjectScoringScaleInput>>;
};

export type CreateAcmProjectParticipantInput = {
  assessorEmails?: InputMaybe<Array<Scalars['String']>>;
  email: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateAcmProjectParticipantsInput = {
  participants: Array<CreateAcmProjectParticipantInput>;
};

export type CreateAcmProjectScoringScaleInput = {
  color: Scalars['String'];
  from: Scalars['Float'];
  name: Scalars['String'];
  to: Scalars['Float'];
};

export type CreateCaseStudyInput = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  maxCandidates?: InputMaybe<Scalars['Float']>;
  minCandidates?: InputMaybe<Scalars['Float']>;
  performingDuration?: InputMaybe<Scalars['String']>;
  preparationDuration?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateCompetencyGroupInput = {
  defaultLocaleCode: Scalars['String'];
  definition: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type CreateCompetencyItemInput = {
  competencyId: Scalars['String'];
  localeCode: Scalars['String'];
  measure: Scalars['String'];
  order: Scalars['String'];
  questionTitle: Scalars['String'];
  responseStatements?: InputMaybe<Array<CreateCompetencyItemResponseStatementInput>>;
  responses: Array<CreateCompetencyItemResponseInput>;
  responsesLogic?: InputMaybe<Array<CreateCompetencyItemResponseLogicInput>>;
};

export type CreateCompetencyItemResponseInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type CreateCompetencyItemResponseLogicInput = {
  responseId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateCompetencyItemResponseStatementInput = {
  responseIds: Array<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateDemographicInput = {
  order: Scalars['String'];
  required?: InputMaybe<Scalars['Boolean']>;
  shortAnswerValidation: Scalars['String'];
  type: Scalars['String'];
};

export type CreateDemographicTemplateInput = {
  defaultLocaleCode: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateKpiInput = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  localeCodes: Array<Scalars['String']>;
  negativeStatement: Array<LocaleEntryInput>;
  notes?: InputMaybe<Array<CreateKpiNoteInput>>;
  positiveStatement?: InputMaybe<Array<LocaleEntryInput>>;
  score1Narratives?: InputMaybe<Array<CreateKpiScoreNarrativeInput>>;
  score1NarrativesStatus?: InputMaybe<Scalars['String']>;
  score2Narratives?: InputMaybe<Array<CreateKpiScoreNarrativeInput>>;
  score2NarrativesStatus?: InputMaybe<Scalars['String']>;
  score3Narratives?: InputMaybe<Array<CreateKpiScoreNarrativeInput>>;
  score3NarrativesStatus?: InputMaybe<Scalars['String']>;
  score4Narratives?: InputMaybe<Array<CreateKpiScoreNarrativeInput>>;
  score4NarrativesStatus?: InputMaybe<Scalars['String']>;
  score5Narratives?: InputMaybe<Array<CreateKpiScoreNarrativeInput>>;
  score5NarrativesStatus?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateKpiNoteInput = {
  text: Array<LocaleEntryInput>;
};

export type CreateKpiScoreNarrativeInput = {
  text?: InputMaybe<Array<LocaleEntryInput>>;
};

export type CreateMasterProductAssessmentInput = {
  adsAssessmentName: Scalars['String'];
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type CreateMasterProductInput = {
  assessments: Array<CreateMasterProductAssessmentInput>;
  description: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  units: Scalars['Float'];
};

export type CreateOrganizationInput = {
  domain: Scalars['String'];
  jobTitle: Scalars['String'];
  name: Scalars['String'];
};

export type CreateProductAssessmentInput = {
  adsAssessmentName: Scalars['String'];
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type CreateProductInput = {
  assessments: Array<CreateProductAssessmentInput>;
  description: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type CreateProductNarrativeInput = {
  content?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<Scalars['String']>;
  indicatorId?: InputMaybe<Scalars['String']>;
  langCode?: InputMaybe<Scalars['String']>;
  order: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type CreateProjectInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type CreateSelfRaterParticipantInput = {
  email: Scalars['String'];
  participantTypeId: Scalars['String'];
};

export type CreateSelfRaterParticipantsInput = {
  participants: Array<CreateSelfRaterParticipantInput>;
};

export type CreateStandardSurveyPageInput = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type CreateStdPageItemInput = {
  order: Scalars['String'];
  type: Scalars['String'];
};

export type CreateSurveyCompetencyItemInput = {
  localeCode: Scalars['String'];
  measure: Scalars['String'];
  order: Scalars['String'];
  questionTitle: Scalars['String'];
  responseStatements?: InputMaybe<Array<CreateSurveyCompetencyItemResponseStatementInput>>;
  responses: Array<CreateSurveyCompetencyItemResponseInput>;
  responsesLogic?: InputMaybe<Array<CreateSurveyCompetencyItemResponseLogicInput>>;
  surveyCompetencyId: Scalars['String'];
};

export type CreateSurveyCompetencyItemResponseInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type CreateSurveyCompetencyItemResponseLogicInput = {
  responseId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateSurveyCompetencyItemResponseStatementInput = {
  responseIds: Array<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateSurveyInput = {
  defaultLocaleCode: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  masterSurveyUnits?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  responsesLayout?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DeleteAcmProjectParticipantsResponse = {
  __typename?: 'DeleteAcmProjectParticipantsResponse';
  deletedParticipants: Array<AcmProjectParticipantResponse>;
  updatedParticipants?: Maybe<Array<AcmProjectParticipantResponse>>;
};

export type DeleteCompetencyItemInput = {
  _id: Scalars['String'];
  competencyId: Scalars['String'];
};

export type DeleteMemberOrInvitationInput = {
  _id: Scalars['String'];
  type: Scalars['String'];
};

export type DeleteMembersOrInvitationsInput = {
  entries: Array<DeleteMemberOrInvitationInput>;
};

export type DeleteSurveyCompetencyItemInput = {
  _id: Scalars['String'];
  surveyCompetencyId: Scalars['String'];
};

export type DemographicAnswerResponse = {
  __typename?: 'DemographicAnswerResponse';
  _id?: Maybe<Scalars['String']>;
  text?: Maybe<Array<LocaleResponse>>;
};

export type DemographicResponse = {
  __typename?: 'DemographicResponse';
  _id: Scalars['String'];
  answers?: Maybe<Array<DemographicAnswerResponse>>;
  createdAt: Scalars['DateTime'];
  customErrorMessage?: Maybe<Array<LocaleResponse>>;
  label?: Maybe<Array<LocaleResponse>>;
  multipleChoicesValidationType?: Maybe<Scalars['String']>;
  multipleChoicesValidationValue?: Maybe<Scalars['Float']>;
  order: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  shortAnswerValidation?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type DemographicTemplateResponse = {
  __typename?: 'DemographicTemplateResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type DemographicTemplateWithCountResponse = {
  __typename?: 'DemographicTemplateWithCountResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  demographicsCount: Scalars['Float'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type DemographicTemplatesWithCountResponse = {
  __typename?: 'DemographicTemplatesWithCountResponse';
  docs: Array<DemographicTemplateWithCountResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type DynamicReport360 = {
  __typename?: 'DynamicReport360';
  actualRatersDescription: Scalars['String'];
  competencies: Array<Competency360ReportResponse>;
  competenciesComments?: Maybe<Array<CompetencyComments360ReportResponse>>;
  completed: Scalars['Float'];
  completedPercentage: Scalars['Float'];
  endDate: Scalars['DateTime'];
  isSelfRaterCompleted: Scalars['Boolean'];
  noCompleted: Scalars['Float'];
  participantsStatusAsGroup: Array<ParticipantsTypeGroup>;
  projectName: Scalars['String'];
  selfRaterName: Scalars['String'];
  skillSignificantGraph: Array<Array<Array<Scalars['String']>>>;
  startDate: Scalars['DateTime'];
  summary: Scalars['String'];
  surveyDuration: Scalars['String'];
};

export type ExportStdQuestionsAndAnswersCsvResponse = {
  __typename?: 'ExportStdQuestionsAndAnswersCsvResponse';
  stdAnswersCsv: Scalars['String'];
  stdQuestionsCsv: Scalars['String'];
};

export type ForgetPasswordInput = {
  email: Scalars['String'];
};

export type ForgetPasswordResponse = {
  __typename?: 'ForgetPasswordResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type GenerateStdProjectTokensInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type HeatmapMatrixRowDrillDownResponse = {
  __typename?: 'HeatmapMatrixRowDrillDownResponse';
  key: Scalars['String'];
  markedAs: Scalars['String'];
  value: Scalars['Float'];
};

export type HeatmapMatrixRowResponse = {
  __typename?: 'HeatmapMatrixRowResponse';
  dimensionId: Scalars['String'];
  dimensionName: Scalars['String'];
  drillDowns: Array<HeatmapMatrixRowDrillDownResponse>;
};

export type InvitationResponse = {
  __typename?: 'InvitationResponse';
  _id: Scalars['String'];
  email: Scalars['String'];
  invitationCodeExpires: Scalars['DateTime'];
  roleId: Scalars['String'];
  roleName: Scalars['String'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  roleId: Scalars['String'];
};

export type JoinRequestResponse = {
  __typename?: 'JoinRequestResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  organizationId: Scalars['String'];
  userEmail?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userImage?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type JoinRequestsResponse = {
  __typename?: 'JoinRequestsResponse';
  docs: Array<JoinRequestResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type KpiNoteResponse = {
  __typename?: 'KpiNoteResponse';
  _id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  text: Array<LocaleEntryResponse>;
};

export type KpiResponse = {
  __typename?: 'KpiResponse';
  _id: Scalars['String'];
  competencyIds?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  localeCodes: Array<Scalars['String']>;
  negativeStatement: Array<LocaleEntryResponse>;
  notes?: Maybe<Array<KpiNoteResponse>>;
  positiveStatement?: Maybe<Array<LocaleEntryResponse>>;
  score1Narratives?: Maybe<Array<KpiScoreNarrativeResponse>>;
  score1NarrativesStatus?: Maybe<Scalars['String']>;
  score2Narratives?: Maybe<Array<KpiScoreNarrativeResponse>>;
  score2NarrativesStatus?: Maybe<Scalars['String']>;
  score3Narratives?: Maybe<Array<KpiScoreNarrativeResponse>>;
  score3NarrativesStatus?: Maybe<Scalars['String']>;
  score4Narratives?: Maybe<Array<KpiScoreNarrativeResponse>>;
  score4NarrativesStatus?: Maybe<Scalars['String']>;
  score5Narratives?: Maybe<Array<KpiScoreNarrativeResponse>>;
  score5NarrativesStatus?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type KpiScoreNarrativeResponse = {
  __typename?: 'KpiScoreNarrativeResponse';
  _id: Scalars['String'];
  text?: Maybe<Array<LocaleEntryResponse>>;
};

export type KpisResponse = {
  __typename?: 'KpisResponse';
  docs: Array<KpiResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type LanguageResponse = {
  __typename?: 'LanguageResponse';
  code: Scalars['String'];
  dir: Scalars['String'];
  name: Scalars['String'];
  nativeName: Scalars['String'];
};

export type LinkGeneratorInput = {
  emailBody?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  productIds: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type LinkKpisWithCaseStudyAndCompetencyInput = {
  competencyId: Scalars['String'];
  linkedKpis: Array<LinkedKpiInput>;
};

export type LinkedKpiInput = {
  kpiId: Scalars['String'];
  order: Scalars['String'];
};

export type LocaleEntryInput = {
  localeCode: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};

export type LocaleEntryResponse = {
  __typename?: 'LocaleEntryResponse';
  localeCode: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type LocaleResponse = {
  __typename?: 'LocaleResponse';
  localeCode: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type MasterProductAssessmentResponse = {
  __typename?: 'MasterProductAssessmentResponse';
  _id: Scalars['String'];
  adsAssessmentName?: Maybe<Scalars['String']>;
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type MasterProductResponse = {
  __typename?: 'MasterProductResponse';
  _id: Scalars['String'];
  assessments?: Maybe<Array<MasterProductAssessmentResponse>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  units: Scalars['Float'];
};

export type MasterProductWithNarrativesCountResponse = {
  __typename?: 'MasterProductWithNarrativesCountResponse';
  _id: Scalars['String'];
  assessments?: Maybe<Array<MasterProductAssessmentResponse>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  narrativesCount: Scalars['Float'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  units: Scalars['Float'];
};

export type MasterProductsResponse = {
  __typename?: 'MasterProductsResponse';
  docs: Array<MasterProductResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type MembersAndInvitationsResponse = {
  __typename?: 'MembersAndInvitationsResponse';
  invitations: Array<InvitationResponse>;
  members: Array<OrganizationMemberResponse>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptJoinRequest: SuccessResponse;
  addUnits: OrganizationResponse;
  answerCompetencyItem: AnswerCompetencyItemResponse;
  answerStdSessionDemographic: AnswerStdSessionDemographicResponse;
  answerStdSessionOpenEndedQuestion: AnswerStdSessionOpenEndedQuestionResponse;
  answerStdSessionPageItemQuestion: AnswerStdSessionPageItemQuestionResponse;
  answerSubItem: AnswerSubItemResponse;
  approveStdSessionPolicy: SuccessResponse;
  approveSurveyPolicy: SuccessResponse;
  cancelJoiningRequest: JoinRequestResponse;
  cloneCompetenciesToSurvey: SuccessResponse;
  commentCompetency: CommentCompetencyResponse;
  createAcmCompetency: AcmCompetencyResponse;
  createAcmProject: AcmProjectResponse;
  createAcmProjectCompetency: AcmProjectCompetencyResponse;
  createAcmProjectParticipants: Array<AcmProjectParticipantResponse>;
  createCaseStudy: CaseStudyResponse;
  createCaseStudyFile: AcmCaseStudyFileResponse;
  createCompetency: CompetencyResponse;
  createCompetencyGroup: CompetencyGroupWithChildrenResponse;
  createCompetencyItem: CompetencyItemResponse;
  createDemographic: DemographicResponse;
  createDemographicTemplate: DemographicTemplateResponse;
  createKpi: KpiResponse;
  createMasterProduct: MasterProductResponse;
  createMasterProductNarrative: ProductNarrativeResponse;
  createOrganization: MyOrganizationResponse;
  createProduct: ProductResponse;
  createProductNarrative: ProductNarrativeResponse;
  createProject: Scalars['String'];
  createSelfRaterParticipants: SuccessResponse;
  createStandardSurveyPage: StandardSurveyPageResponse;
  createStdPageItem: StdPageItemResponse;
  createStdProject: Scalars['String'];
  createSurvey: SurveyResponse;
  createSurveyCompetencyItem: CompetencyItemResponse;
  deleteAcmCompetency: AcmCompetencyResponse;
  deleteAcmProject: AcmProjectResponse;
  deleteAcmProjectCompetency: AcmProjectCompetencyResponse;
  deleteAcmProjectParticipants: DeleteAcmProjectParticipantsResponse;
  deleteAdsApiKey: SuccessResponse;
  deleteCaseStudy: CaseStudyResponse;
  deleteCaseStudyFile: AcmCaseStudyFileResponse;
  deleteCaseStudyKpi: CaseStudyKpiResponse;
  deleteCompetency: SuccessResponse;
  deleteCompetencyGroup: SuccessResponse;
  deleteCompetencyItem: SuccessResponse;
  deleteDemographic: SuccessResponse;
  deleteDemographicTemplate: SuccessResponse;
  deleteKpi: KpiResponse;
  deleteMasterProduct: SuccessResponse;
  deleteMasterProductIndicator: SuccessResponse;
  deleteMasterProductNarrative: SuccessResponse;
  deleteMembersAndInvitations: SuccessResponse;
  deleteProduct: SuccessResponse;
  deleteProductIndicator: SuccessResponse;
  deleteProductNarrative: SuccessResponse;
  deleteSelfRaterParticipant: SuccessResponse;
  deleteStandardSurveyPage: SuccessResponse;
  deleteStdPageItem: SuccessResponse;
  deleteStdProject: SuccessResponse;
  deleteStdProjectSessions: SuccessResponse;
  deleteStdReports: SuccessResponse;
  deleteSurvey: SuccessResponse;
  deleteSurveyCompetency: SuccessResponse;
  deleteSurveyCompetencyItem: SuccessResponse;
  duplicateCompetency: CompetencyWithItemsResponse;
  duplicateCompetencyItem: CompetencyItemResponse;
  duplicateDemographicTemplate: DemographicTemplateWithCountResponse;
  duplicateSurvey: SurveyResponse;
  duplicateSurveyCompetency: SurveyCompetencyWithItemsResponse;
  duplicateSurveyCompetencyItem: CompetencyItemResponse;
  endSession: SuccessResponse;
  endStdSession: SuccessResponse;
  exportMasterProductIndicatorsCsvTemplate: Scalars['String'];
  exportMasterProductNarrativesCsvTemplate: Scalars['String'];
  exportProductIndicatorsCsvTemplate: Scalars['String'];
  exportProductNarrativesCsvTemplate: Scalars['String'];
  extendStdProjectEndDate: SuccessResponse;
  forgetPassword: ForgetPasswordResponse;
  generateProductPdfReport?: Maybe<Scalars['String']>;
  generateProductReport: Scalars['String'];
  generateSingleProductAssessmentReport: SuccessResponse;
  generateStdProjectCsvReport: SuccessResponse;
  generateStdProjectTokens: Scalars['String'];
  generateUniqueDomain: Scalars['String'];
  importMasterProductIndicatorsCsvTemplate: Array<ProductIndicatorResponse>;
  importMasterProductNarrativeCsvTemplate: SuccessResponse;
  importParticipantsCsv: Array<ParticipantsCsvResponse>;
  importProductIndicatorsCsvTemplate: Array<ProductIndicatorResponse>;
  importProductNarrativeCsvTemplate: SuccessResponse;
  importStdAnswersFromCsv: SuccessResponse;
  importStdQuestionsFromCsv: SuccessResponse;
  inviteUsers: SuccessResponse;
  joinOrganization: JoinRequestResponse;
  joinOrganizationViaInvitation: SuccessResponse;
  linkKpisWithCaseStudyAndCompetency: Array<CaseStudyKpiResponse>;
  linkProductsToOrganization: Array<ProductResponse>;
  logout: SuccessResponse;
  passInfoPage: SuccessResponse;
  passStdSessionDemographicsPage: SuccessResponse;
  passStdSessionInfoPage: SuccessResponse;
  productLinkGenerator: Scalars['String'];
  refreshToken: RefreshTokenResponse;
  rejectJoinRequest: SuccessResponse;
  resendInvitation: SuccessResponse;
  resendProductSession: Scalars['String'];
  resendStdProjectSessions: SuccessResponse;
  resendSurveySession: SuccessResponse;
  resendVerification: SuccessResponse;
  resetPassword: SuccessResponse;
  resetPasswordViaToken: SuccessResponse;
  restoreAcmProject: AcmProjectResponse;
  restoreCaseStudy: CaseStudyResponse;
  restoreCompetencyGroup: SuccessResponse;
  restoreDemographicTemplate: SuccessResponse;
  restoreMasterProduct: SuccessResponse;
  restoreProduct: SuccessResponse;
  restoreStdProject: SuccessResponse;
  restoreSurvey: SuccessResponse;
  selectLocale: Scalars['String'];
  selectStdLocale: Scalars['String'];
  sendBulkProducts: SuccessResponse;
  sendProductsToIndividual: SuccessResponse;
  sendProject: SuccessResponse;
  sendResetPasswordEmail: SuccessResponse;
  sendStdProject2: SuccessResponse;
  setAdsApiKey: Scalars['String'];
  setMasterProductIndicators: Array<ProductIndicatorResponse>;
  setProductIndicators: Array<ProductIndicatorResponse>;
  setProjectSettings: ProjectSettingsResponse;
  setStandardSurveyDimensions: Array<StdDimensionResponse>;
  setStdProjectSettings: StdProjectSettingsResponse;
  signin: SigninResponse;
  signup: SignupResponse;
  superAdminGenerateUniqueDomain: Scalars['String'];
  superAdminUpdateOrganization: MyOrganizationWithJobTitleResponse;
  syncStdProjectTypo: SuccessResponse;
  toggleOrganizationActivation: SuccessResponse;
  transferMasterSurveys: SuccessResponse;
  trashAcmProject: AcmProjectResponse;
  trashCaseStudy: CaseStudyResponse;
  trashCompetencyGroup: SuccessResponse;
  trashDemographicTemplate: SuccessResponse;
  trashMasterProduct: SuccessResponse;
  trashProduct: SuccessResponse;
  trashStdProject: SuccessResponse;
  trashSurvey: SuccessResponse;
  updateAcmCompetency: AcmCompetencyResponse;
  updateAcmProject: AcmProjectResponse;
  updateAcmProjectCompetency: AcmProjectCompetencyResponse;
  updateAcmProjectParticipants: Array<AcmProjectParticipantResponse>;
  updateCaseStudy: CaseStudyResponse;
  updateCaseStudyFile: AcmCaseStudyFileResponse;
  updateCaseStudyKpiOrder: CaseStudyKpiResponse;
  updateCompetencyGroup: CompetencyGroupResponse;
  updateCompetencyItem: CompetencyItemResponse;
  updateCompetencyItemOrder: CompetencyItemResponse;
  updateCompetencyItemResponseLogic: CompetencySubItemResponse;
  updateCompetencyLocale: CompetencyResponse;
  updateDemographic: DemographicResponse;
  updateDemographicOrder: DemographicResponse;
  updateDemographicTemplate: DemographicTemplateResponse;
  updateKpi: KpiResponse;
  updateMasterProduct: MasterProductResponse;
  updateMasterProductNarrative: ProductNarrativeResponse;
  updateMyProfile: UserResponse;
  updateOrganization: MyOrganizationWithJobTitleResponse;
  updateOrganizationAccess: SuccessResponse;
  updateProduct: ProductResponse;
  updateProductNarrative: ProductNarrativeResponse;
  updateProductSession: SuccessResponse;
  updateProject: ProjectResponse;
  updateRoles: SuccessResponse;
  updateSelfRaterEndDate: SuccessResponse;
  updateSelfRaterParticipant: SuccessResponse;
  updateStandardSurveyPage: StandardSurveyPageResponse;
  updateStdPageItem: StdPageItemResponse;
  updateStdPageItemOrder: StdPageItemResponse;
  updateStdProject: StdProjectResponse;
  updateStdProjectSession: SuccessResponse;
  updateSurvey: SurveyResponse;
  updateSurveyCompetencyItem: CompetencyItemResponse;
  updateSurveyCompetencyItemOrder: CompetencyItemResponse;
  updateSurveyCompetencyItemResponseLogic: CompetencySubItemResponse;
  updateSurveyCompetencyLocale: SurveyCompetencyResponse;
  updateSurveyLocale: SurveyWithLocalesResponse;
  verifyAccount: SigninResponse;
};


export type MutationAcceptJoinRequestArgs = {
  input: AcceptJoinRequestInput;
  organizationId: Scalars['String'];
};


export type MutationAddUnitsArgs = {
  input: AddUnitsInput;
};


export type MutationAnswerCompetencyItemArgs = {
  input: AnswerCompetencyItemInput;
  sessionToken: Scalars['String'];
};


export type MutationAnswerStdSessionDemographicArgs = {
  input: AnswerStdSessionDemographicInput;
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationAnswerStdSessionOpenEndedQuestionArgs = {
  input: AnswerStdSessionOpenEndedQuestionInput;
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationAnswerStdSessionPageItemQuestionArgs = {
  input: AnswerStdSessionPageItemQuestionInput;
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationAnswerSubItemArgs = {
  input: AnswerSubItemInput;
  sessionToken: Scalars['String'];
};


export type MutationApproveStdSessionPolicyArgs = {
  approved: Scalars['Boolean'];
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationApproveSurveyPolicyArgs = {
  approved: Scalars['Boolean'];
  sessionToken: Scalars['String'];
};


export type MutationCancelJoiningRequestArgs = {
  joinRequestId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationCloneCompetenciesToSurveyArgs = {
  clonedCompetencies: Array<CloneCompetencyInput>;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCommentCompetencyArgs = {
  input: CommentCompetencyInput;
  sessionToken: Scalars['String'];
};


export type MutationCreateAcmCompetencyArgs = {
  input: CreateAcmCompetencyInput;
  organizationId: Scalars['String'];
};


export type MutationCreateAcmProjectArgs = {
  companyImageFile?: InputMaybe<Scalars['Upload']>;
  input: CreateAcmProjectInput;
  organizationId: Scalars['String'];
};


export type MutationCreateAcmProjectCompetencyArgs = {
  acmProjectId: Scalars['String'];
  input: CreateAcmProjectCompetencyInput;
  organizationId: Scalars['String'];
};


export type MutationCreateAcmProjectParticipantsArgs = {
  acmProjectId: Scalars['String'];
  input: CreateAcmProjectParticipantsInput;
  organizationId: Scalars['String'];
};


export type MutationCreateCaseStudyArgs = {
  input: CreateCaseStudyInput;
  organizationId: Scalars['String'];
};


export type MutationCreateCaseStudyFileArgs = {
  caseStudyId: Scalars['String'];
  file: Scalars['Upload'];
  input: CreateAcmCaseStudyFileInput;
  organizationId: Scalars['String'];
  thumbnailFile?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateCompetencyArgs = {
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationCreateCompetencyGroupArgs = {
  input: CreateCompetencyGroupInput;
};


export type MutationCreateCompetencyItemArgs = {
  competencyGroupId: Scalars['String'];
  input: CreateCompetencyItemInput;
  organizationId: Scalars['String'];
};


export type MutationCreateDemographicArgs = {
  demographicTemplateId: Scalars['String'];
  input: CreateDemographicInput;
  organizationId: Scalars['String'];
};


export type MutationCreateDemographicTemplateArgs = {
  input: CreateDemographicTemplateInput;
  organizationId: Scalars['String'];
};


export type MutationCreateKpiArgs = {
  input: CreateKpiInput;
  organizationId: Scalars['String'];
};


export type MutationCreateMasterProductArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: CreateMasterProductInput;
};


export type MutationCreateMasterProductNarrativeArgs = {
  input: CreateProductNarrativeInput;
  masterProductId: Scalars['String'];
};


export type MutationCreateOrganizationArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: CreateOrganizationInput;
};


export type MutationCreateProductArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: CreateProductInput;
  organizationId: Scalars['String'];
};


export type MutationCreateProductNarrativeArgs = {
  input: CreateProductNarrativeInput;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCreateSelfRaterParticipantsArgs = {
  input: CreateSelfRaterParticipantsInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type MutationCreateStandardSurveyPageArgs = {
  input: CreateStandardSurveyPageInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCreateStdPageItemArgs = {
  input: CreateStdPageItemInput;
  organizationId: Scalars['String'];
  stdPageId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCreateStdProjectArgs = {
  input: CreateProjectInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
  organizationId: Scalars['String'];
};


export type MutationCreateSurveyCompetencyItemArgs = {
  input: CreateSurveyCompetencyItemInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDeleteAcmCompetencyArgs = {
  acmCompetencyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteAcmProjectArgs = {
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteAcmProjectCompetencyArgs = {
  acmProjectCompetencyId: Scalars['String'];
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteAcmProjectParticipantsArgs = {
  acmProjectId: Scalars['String'];
  acmProjectParticipantIds: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteAdsApiKeyArgs = {
  key: Scalars['String'];
};


export type MutationDeleteCaseStudyArgs = {
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCaseStudyFileArgs = {
  caseFileId: Scalars['String'];
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCaseStudyKpiArgs = {
  caseStudyId: Scalars['String'];
  caseStudyKpiId: Scalars['String'];
  competencyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCompetencyArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCompetencyGroupArgs = {
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteCompetencyItemArgs = {
  competencyGroupId: Scalars['String'];
  input: DeleteCompetencyItemInput;
  organizationId: Scalars['String'];
};


export type MutationDeleteDemographicArgs = {
  demographicId: Scalars['String'];
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteKpiArgs = {
  kpiId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDeleteMasterProductArgs = {
  masterProductId: Scalars['String'];
};


export type MutationDeleteMasterProductIndicatorArgs = {
  _id: Scalars['String'];
  masterProductId: Scalars['String'];
};


export type MutationDeleteMasterProductNarrativeArgs = {
  _id: Scalars['String'];
  masterProductId: Scalars['String'];
};


export type MutationDeleteMembersAndInvitationsArgs = {
  input: DeleteMembersOrInvitationsInput;
  organizationId: Scalars['String'];
};


export type MutationDeleteProductArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationDeleteProductIndicatorArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationDeleteProductNarrativeArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationDeleteSelfRaterParticipantArgs = {
  organizationId: Scalars['String'];
  participantId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type MutationDeleteStandardSurveyPageArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDeleteStdPageItemArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  stdPageId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDeleteStdProjectArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationDeleteStdProjectSessionsArgs = {
  isAllSelected?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  sessionIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationDeleteStdReportsArgs = {
  organizationId: Scalars['String'];
  stdReportsIds: Array<Scalars['String']>;
};


export type MutationDeleteSurveyArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDeleteSurveyCompetencyArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDeleteSurveyCompetencyItemArgs = {
  input: DeleteSurveyCompetencyItemInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDuplicateCompetencyArgs = {
  _id: Scalars['String'];
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDuplicateCompetencyItemArgs = {
  _id: Scalars['String'];
  competencyGroupId: Scalars['String'];
  competencyId: Scalars['String'];
  newCompetencyItemOrder: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDuplicateDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationDuplicateSurveyArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDuplicateSurveyCompetencyArgs = {
  _id: Scalars['String'];
  newSurveyCompetencyOrder: Scalars['String'];
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationDuplicateSurveyCompetencyItemArgs = {
  _id: Scalars['String'];
  newSurveyCompetencyItemOrder: Scalars['String'];
  organizationId: Scalars['String'];
  surveyCompetencyId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationEndSessionArgs = {
  sessionToken: Scalars['String'];
};


export type MutationEndStdSessionArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationExportMasterProductIndicatorsCsvTemplateArgs = {
  masterProductId: Scalars['String'];
};


export type MutationExportMasterProductNarrativesCsvTemplateArgs = {
  masterProductId: Scalars['String'];
};


export type MutationExportProductIndicatorsCsvTemplateArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationExportProductNarrativesCsvTemplateArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationExtendStdProjectEndDateArgs = {
  endDate: Scalars['DateTime'];
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationForgetPasswordArgs = {
  input: ForgetPasswordInput;
};


export type MutationGenerateProductPdfReportArgs = {
  email: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productSendId: Scalars['String'];
};


export type MutationGenerateProductReportArgs = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  startAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationGenerateSingleProductAssessmentReportArgs = {
  adsAssessmentId: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationGenerateStdProjectCsvReportArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationGenerateStdProjectTokensArgs = {
  input: GenerateStdProjectTokensInput;
  organizationId: Scalars['String'];
  participantsCsv?: InputMaybe<Scalars['Upload']>;
  projectId: Scalars['String'];
};


export type MutationGenerateUniqueDomainArgs = {
  organizationName: Scalars['String'];
};


export type MutationImportMasterProductIndicatorsCsvTemplateArgs = {
  ProductIndicatorsCsv: Scalars['Upload'];
  masterProductId: Scalars['String'];
};


export type MutationImportMasterProductNarrativeCsvTemplateArgs = {
  masterProductId: Scalars['String'];
  productNarrativesCsv: Scalars['Upload'];
};


export type MutationImportParticipantsCsvArgs = {
  organizationId: Scalars['String'];
  participantsCsv?: InputMaybe<Scalars['Upload']>;
  projectId: Scalars['String'];
};


export type MutationImportProductIndicatorsCsvTemplateArgs = {
  ProductIndicatorsCsv: Scalars['Upload'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationImportProductNarrativeCsvTemplateArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productNarrativesCsv: Scalars['Upload'];
};


export type MutationImportStdAnswersFromCsvArgs = {
  organizationId: Scalars['String'];
  stdAnswersCsv?: InputMaybe<Scalars['Upload']>;
  surveyId: Scalars['String'];
};


export type MutationImportStdQuestionsFromCsvArgs = {
  organizationId: Scalars['String'];
  stdQuestionsCsv?: InputMaybe<Scalars['Upload']>;
  surveyId: Scalars['String'];
};


export type MutationInviteUsersArgs = {
  invitedUsers?: InputMaybe<Array<InviteUserInput>>;
  invitedUsersCsv?: InputMaybe<Scalars['Upload']>;
  organizationId: Scalars['String'];
};


export type MutationJoinOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationJoinOrganizationViaInvitationArgs = {
  invitationToken: Scalars['String'];
};


export type MutationLinkKpisWithCaseStudyAndCompetencyArgs = {
  caseStudyId: Scalars['String'];
  input: LinkKpisWithCaseStudyAndCompetencyInput;
  organizationId: Scalars['String'];
};


export type MutationLinkProductsToOrganizationArgs = {
  masterProductsIds: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};


export type MutationPassInfoPageArgs = {
  sessionToken: Scalars['String'];
};


export type MutationPassStdSessionDemographicsPageArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationPassStdSessionInfoPageArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationProductLinkGeneratorArgs = {
  candidatesCsv?: InputMaybe<Scalars['Upload']>;
  input: LinkGeneratorInput;
  organizationId: Scalars['String'];
};


export type MutationRejectJoinRequestArgs = {
  joinRequestId: Scalars['String'];
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationResendInvitationArgs = {
  organizationId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationResendProductSessionArgs = {
  assessmentId: Scalars['String'];
  email: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productSendId: Scalars['String'];
  resendEmail?: InputMaybe<Scalars['Boolean']>;
  withNewToken?: InputMaybe<Scalars['Boolean']>;
};


export type MutationResendStdProjectSessionsArgs = {
  isAllSelected?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  sessionIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationResendSurveySessionArgs = {
  organizationId: Scalars['String'];
  participantId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type MutationResendVerificationArgs = {
  input: ResendVerificationCodeInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordViaTokenArgs = {
  input: ChangePasswordInput;
};


export type MutationRestoreAcmProjectArgs = {
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationRestoreCaseStudyArgs = {
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationRestoreCompetencyGroupArgs = {
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationRestoreDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationRestoreMasterProductArgs = {
  masterProductId: Scalars['String'];
};


export type MutationRestoreProductArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationRestoreStdProjectArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationRestoreSurveyArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSelectLocaleArgs = {
  input: SelectLocaleInput;
  sessionToken: Scalars['String'];
};


export type MutationSelectStdLocaleArgs = {
  input: StdSelectLocaleInput;
  stdSessionInfo: StdSessionInfoArgs;
};


export type MutationSendBulkProductsArgs = {
  candidatesCsv?: InputMaybe<Scalars['Upload']>;
  input: SendProductsInput;
  organizationId: Scalars['String'];
};


export type MutationSendProductsToIndividualArgs = {
  input: SendProductsToIndividualInput;
  organizationId: Scalars['String'];
};


export type MutationSendProjectArgs = {
  input: SendProjectInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationSendStdProject2Args = {
  organizationId: Scalars['String'];
  participantsCsv?: InputMaybe<Scalars['Upload']>;
  projectId: Scalars['String'];
  sendStdProjectInput: SendStdProjectInput;
};


export type MutationSetAdsApiKeyArgs = {
  key: Scalars['String'];
};


export type MutationSetMasterProductIndicatorsArgs = {
  input: Array<SetProductIndicatorInput>;
  masterProductId: Scalars['String'];
};


export type MutationSetProductIndicatorsArgs = {
  input: Array<SetProductIndicatorInput>;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationSetProjectSettingsArgs = {
  input: SetProjectSettingsInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationSetStandardSurveyDimensionsArgs = {
  input: SetStandardDimensionsInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationSetStdProjectSettingsArgs = {
  input: SetStdProjectSettingsInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationSigninArgs = {
  input: SigninInput;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationSuperAdminGenerateUniqueDomainArgs = {
  organizationName: Scalars['String'];
};


export type MutationSuperAdminUpdateOrganizationArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: UpdateOrganizationInput;
  organizationId: Scalars['String'];
};


export type MutationSyncStdProjectTypoArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationToggleOrganizationActivationArgs = {
  active: Scalars['Boolean'];
  organizationId: Scalars['String'];
};


export type MutationTransferMasterSurveysArgs = {
  masterSurveyIds: Array<Scalars['String']>;
  transfereeOrganizationId: Scalars['String'];
};


export type MutationTrashAcmProjectArgs = {
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationTrashCaseStudyArgs = {
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationTrashCompetencyGroupArgs = {
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationTrashDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationTrashMasterProductArgs = {
  masterProductId: Scalars['String'];
};


export type MutationTrashProductArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationTrashStdProjectArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationTrashSurveyArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateAcmCompetencyArgs = {
  acmCompetencyId: Scalars['String'];
  input: UpdateAcmCompetencyInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateAcmProjectArgs = {
  acmProjectId: Scalars['String'];
  companyImageFile?: InputMaybe<Scalars['Upload']>;
  input: UpdateAcmProjectInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateAcmProjectCompetencyArgs = {
  acmProjectCompetencyId: Scalars['String'];
  acmProjectId: Scalars['String'];
  input: UpdateAcmProjectCompetencyInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateAcmProjectParticipantsArgs = {
  acmProjectId: Scalars['String'];
  input: UpdateAcmProjectParticipantsInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateCaseStudyArgs = {
  caseStudyId: Scalars['String'];
  input: UpdateCaseStudyInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateCaseStudyFileArgs = {
  caseFileId: Scalars['String'];
  caseStudyId: Scalars['String'];
  input: UpdateAcmCaseStudyFileInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateCaseStudyKpiOrderArgs = {
  caseStudyId: Scalars['String'];
  caseStudyKpiId: Scalars['String'];
  competencyId: Scalars['String'];
  order: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUpdateCompetencyGroupArgs = {
  input: UpdateCompetencyGroupInput;
};


export type MutationUpdateCompetencyItemArgs = {
  competencyGroupId: Scalars['String'];
  input: UpdateCompetencyItemInput;
  organizationId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateCompetencyItemOrderArgs = {
  competencyGroupId: Scalars['String'];
  input: UpdateCompetencyItemOrderInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateCompetencyItemResponseLogicArgs = {
  competencyGroupId: Scalars['String'];
  input: UpdateCompetencyItemResponseLogicSubItemInput;
  organizationId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateCompetencyLocaleArgs = {
  competencyGroupId: Scalars['String'];
  input: UpdateCompetencyLocaleInput;
  localeCodes: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};


export type MutationUpdateDemographicArgs = {
  demographicId: Scalars['String'];
  demographicTemplateId: Scalars['String'];
  input: UpdateDemographicInput;
  organizationId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateDemographicOrderArgs = {
  demographicId: Scalars['String'];
  demographicTemplateId: Scalars['String'];
  order: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUpdateDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  input: UpdateDemographicTemplateInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateKpiArgs = {
  input: UpdateKpiInput;
  kpiId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUpdateMasterProductArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: UpdateMasterProductInput;
  masterProductId: Scalars['String'];
};


export type MutationUpdateMasterProductNarrativeArgs = {
  _id: Scalars['String'];
  input: UpdateProductNarrativeInput;
  masterProductId: Scalars['String'];
};


export type MutationUpdateMyProfileArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: UpdateMyProfileInput;
};


export type MutationUpdateOrganizationArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: UpdateOrganizationInput;
  organizationId: Scalars['String'];
};


export type MutationUpdateOrganizationAccessArgs = {
  input: UpdateOrganizationAccessInput;
};


export type MutationUpdateProductArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  input: UpdateProductInput;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationUpdateProductNarrativeArgs = {
  _id: Scalars['String'];
  input: UpdateProductNarrativeInput;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationUpdateProductSessionArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productSendId: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateRolesArgs = {
  input: UpdateMembersOrInvitationsInput;
  newRoleId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type MutationUpdateSelfRaterEndDateArgs = {
  input: UpdateProjectSelfRaterDateInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type MutationUpdateSelfRaterParticipantArgs = {
  input: UpdateSelfRaterParticipantInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type MutationUpdateStandardSurveyPageArgs = {
  _id: Scalars['String'];
  input: UpdateStandardSurveyPageInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateStdPageItemArgs = {
  input: UpdateStdPageItemInput;
  organizationId: Scalars['String'];
  stdPageId: Scalars['String'];
  surveyId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateStdPageItemOrderArgs = {
  input: UpdateStdPageItemOrderInput;
  organizationId: Scalars['String'];
  stdPageId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateStdProjectArgs = {
  input: UpdateStdProjectInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateStdProjectSessionArgs = {
  input: UpdateStdProjectSessionInput;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  stdProjectSessionId: Scalars['String'];
};


export type MutationUpdateSurveyArgs = {
  horizontalImage?: InputMaybe<Scalars['Upload']>;
  input: UpdateSurveyInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
  verticalImage?: InputMaybe<Scalars['Upload']>;
};


export type MutationUpdateSurveyCompetencyItemArgs = {
  input: UpdateSurveyCompetencyItemInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateSurveyCompetencyItemOrderArgs = {
  input: UpdateSurveyCompetencyItemOrderInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateSurveyCompetencyItemResponseLogicArgs = {
  input: UpdateSurveyCompetencyItemResponseLogicSubItemInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
  withLocaleCodes: Array<Scalars['String']>;
};


export type MutationUpdateSurveyCompetencyLocaleArgs = {
  input: UpdateSurveyCompetencyLocaleInput;
  localeCodes: Array<Scalars['String']>;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationUpdateSurveyLocaleArgs = {
  input: UpdateSurveyLocaleInput;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type MutationVerifyAccountArgs = {
  input: VerifyInput;
};

export type MyOrganizationAccessResponse = {
  __typename?: 'MyOrganizationAccessResponse';
  assessmentModule?: Maybe<Scalars['Boolean']>;
  surveyModule?: Maybe<Scalars['Boolean']>;
};

export type MyOrganizationResponse = {
  __typename?: 'MyOrganizationResponse';
  _id: Scalars['String'];
  access?: Maybe<MyOrganizationAccessResponse>;
  active: Scalars['Boolean'];
  adsApiKey?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  units?: Maybe<Scalars['Float']>;
};

export type MyOrganizationWithJobTitleResponse = {
  __typename?: 'MyOrganizationWithJobTitleResponse';
  _id: Scalars['String'];
  access?: Maybe<MyOrganizationAccessResponse>;
  active: Scalars['Boolean'];
  adsApiKey?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  units?: Maybe<Scalars['Float']>;
};

export type MyProductReportResponse = {
  __typename?: 'MyProductReportResponse';
  groupId: Scalars['String'];
  organizationId: Scalars['String'];
  organizationImage?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productImage?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productSendId: Scalars['String'];
};

export type Narrative360ReportResponse = {
  __typename?: 'Narrative360ReportResponse';
  text?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type OrganizationMemberResponse = {
  __typename?: 'OrganizationMemberResponse';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationResponse = {
  __typename?: 'OrganizationResponse';
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  domain?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  units?: Maybe<Scalars['Float']>;
};

export type OrganizationsResponse = {
  __typename?: 'OrganizationsResponse';
  docs: Array<OrganizationResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type PaginationArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
};

export type PartialProductResponse = {
  __typename?: 'PartialProductResponse';
  _id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ParticipantEmailTemplateResponse = {
  __typename?: 'ParticipantEmailTemplateResponse';
  _id: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  participantTypeId: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type ParticipantResponse = {
  __typename?: 'ParticipantResponse';
  _id: Scalars['String'];
  email: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  participantType: Scalars['String'];
  participantTypeId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type ParticipantStatus = {
  __typename?: 'ParticipantStatus';
  completed: Scalars['Boolean'];
  email: Scalars['String'];
};

export type ParticipantTypeResponse = {
  __typename?: 'ParticipantTypeResponse';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type ParticipantsComments = {
  __typename?: 'ParticipantsComments';
  comments: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type ParticipantsCsvResponse = {
  __typename?: 'ParticipantsCsvResponse';
  email: Scalars['String'];
  participantTypeId: Scalars['String'];
};

export type ParticipantsSkillAndSignificant = {
  __typename?: 'ParticipantsSkillAndSignificant';
  agreement?: Maybe<Scalars['String']>;
  keyInsights?: Maybe<Scalars['String']>;
  potentialUse?: Maybe<Scalars['String']>;
  significantMean: Scalars['Float'];
  skillMean: Scalars['Float'];
  typeName: Scalars['String'];
};

export type ParticipantsTypeGroup = {
  __typename?: 'ParticipantsTypeGroup';
  participantsStatus: Array<ParticipantStatus>;
  type: Scalars['String'];
};

export type ProductAssessmentResponse = {
  __typename?: 'ProductAssessmentResponse';
  _id: Scalars['String'];
  adsAssessmentName?: Maybe<Scalars['String']>;
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type ProductEmailReportResponse = {
  __typename?: 'ProductEmailReportResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  isProductDeleted?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  productId: Scalars['String'];
  productImage?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  productSendId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userSessions?: Maybe<Array<ProductEmailReportUserSessionResponse>>;
};

export type ProductEmailReportUserSessionResponse = {
  __typename?: 'ProductEmailReportUserSessionResponse';
  _id: Scalars['String'];
  adsAssessmentName?: Maybe<Scalars['String']>;
  assessmentId?: Maybe<Scalars['String']>;
  assessmentLink?: Maybe<Scalars['String']>;
  assessmentName?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
};

export type ProductEmailReportsArgs = {
  filter?: InputMaybe<ProductEmailReportsFilterArgs>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
};

export type ProductEmailReportsFilterArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  productIds?: InputMaybe<Array<Scalars['String']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type ProductEmailReportsResponse = {
  __typename?: 'ProductEmailReportsResponse';
  docs: Array<ProductEmailReportResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ProductIndicatorResponse = {
  __typename?: 'ProductIndicatorResponse';
  _id: Scalars['String'];
  fromScore: Scalars['Float'];
  name: Scalars['String'];
  toScore: Scalars['Float'];
};

export type ProductNarrativeResponse = {
  __typename?: 'ProductNarrativeResponse';
  _id: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  dimension?: Maybe<Scalars['String']>;
  indicatorId?: Maybe<Scalars['String']>;
  langCode?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ProductNarrativesResponse = {
  __typename?: 'ProductNarrativesResponse';
  docs: Array<ProductNarrativeResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ProductResponse = {
  __typename?: 'ProductResponse';
  _id: Scalars['String'];
  assessments?: Maybe<Array<ProductAssessmentResponse>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isSent: Scalars['Boolean'];
  masterProductId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  units: Scalars['Float'];
};

export type ProductResponseWithNarrativesCount = {
  __typename?: 'ProductResponseWithNarrativesCount';
  _id: Scalars['String'];
  assessments?: Maybe<Array<ProductAssessmentResponse>>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isSent: Scalars['Boolean'];
  masterProductId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  narrativesCount: Scalars['Float'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  units: Scalars['Float'];
};

export type ProductSendResponse = {
  __typename?: 'ProductSendResponse';
  _id: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  products: Array<PartialProductResponse>;
  sendType: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  totalSessions: Scalars['Float'];
};

export type ProductSendsResponse = {
  __typename?: 'ProductSendsResponse';
  docs: Array<ProductSendResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ProductSessionResponse = {
  __typename?: 'ProductSessionResponse';
  _id: Scalars['String'];
  assessments?: Maybe<Array<ProductAssessmentResponse>>;
  email?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
};

export type ProductSessionsResponse = {
  __typename?: 'ProductSessionsResponse';
  docs: Array<ProductSessionResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  docs: Array<ProductResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type ProjectResponse = {
  __typename?: 'ProjectResponse';
  _id: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  localeCodes?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  surveyId: Scalars['String'];
  surveyName: Scalars['String'];
  surveyType: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ProjectSettingsResponse = {
  __typename?: 'ProjectSettingsResponse';
  _id: Scalars['String'];
  globalBody?: Maybe<Scalars['String']>;
  globalSubject?: Maybe<Scalars['String']>;
  participantEmailTemplates?: Maybe<Array<ParticipantEmailTemplateResponse>>;
  participantTypes: Array<ParticipantTypeResponse>;
  restrictLogin?: Maybe<Scalars['Boolean']>;
  selfRaterBody?: Maybe<Scalars['String']>;
  selfRaterSubject?: Maybe<Scalars['String']>;
  unifyEmailForAll: Scalars['Boolean'];
};

export type ProjectsResponse = {
  __typename?: 'ProjectsResponse';
  docs: Array<ProjectResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  acmCompetencies: Array<AcmCompetencyResponse>;
  acmCompetency: AcmCompetencyResponse;
  acmProject: AcmProjectResponse;
  acmProjectCompetencyKpis: Array<KpiResponse>;
  acmProjectParticipants: Array<AcmProjectParticipantResponse>;
  adsApiKey?: Maybe<Scalars['String']>;
  assignedAssessments: Array<AssignedAssessmentResponse>;
  caseStudy: CaseStudyResponse;
  caseStudyKpis: Array<CaseStudyKpiResponse>;
  checkStdSession: CheckStdSessionResponse;
  checkSurveySession: CheckSurveySessionResponse;
  competencies: Array<CompetencyWithItemsResponse>;
  competencyGroup: CompetencyGroupWithChildrenResponse;
  countStdSessionsWithTrashedOption: Scalars['Float'];
  demographicTemplate: DemographicTemplateResponse;
  demographics: Array<DemographicResponse>;
  downloadBulkSenderTemplate: Scalars['String'];
  downloadInviteUsersCsvTemplate: Scalars['String'];
  downloadLinksGeneratorTemplate: Scalars['String'];
  downloadParticipantsCsvTemplate: Scalars['String'];
  downloadProductIndicatorsCsvTemplate: Scalars['String'];
  downloadProductNarrativesCsvTemplate: Scalars['String'];
  downloadStdParticipantsCsvTemplate: Scalars['String'];
  exportStdQuestionsAndAnswersToCsv: ExportStdQuestionsAndAnswersCsvResponse;
  generateMyProductReport: Scalars['String'];
  getSystemLanguages: Array<LanguageResponse>;
  isProjectNameExist: Scalars['Boolean'];
  isStdProjectNameExist: Scalars['Boolean'];
  joinRequests: JoinRequestsResponse;
  kpi: KpiResponse;
  lastThreeJoinRequests: Array<JoinRequestResponse>;
  masterProduct: MasterProductWithNarrativesCountResponse;
  masterProductIndicators: Array<ProductIndicatorResponse>;
  masterProductNarrative: ProductNarrativeResponse;
  me: UserResponse;
  membersAndInvitations: MembersAndInvitationsResponse;
  myJoiningRequests: Array<JoinRequestResponse>;
  myOrganization: MyOrganizationWithJobTitleResponse;
  myOrganizations: Array<MyOrganizationResponse>;
  myProductReports: Array<MyProductReportResponse>;
  nextCompetency: CheckSurveySessionResponse;
  nextStdSessionPage: CheckStdSessionResponse;
  organization: SuperAdminOrganizationResponse;
  organizationAdsAssessments: AdsAssessmentsResponse;
  organizationAssignableProducts: MasterProductsResponse;
  prevCompetency: CheckSurveySessionResponse;
  prevStdSessionPage: CheckStdSessionResponse;
  product: ProductResponseWithNarrativesCount;
  productEmailReports: ProductEmailReportsResponse;
  productIndicators: Array<ProductIndicatorResponse>;
  productNarrative: ProductNarrativeResponse;
  productSend: ProductSendResponse;
  project: ProjectResponse;
  projectRawScores360: Scalars['String'];
  projectSettings?: Maybe<ProjectSettingsResponse>;
  report360Data: DynamicReport360;
  report360Pdf: Scalars['String'];
  roles: Array<RoleResponse>;
  searchAcmCaseFiles: AcmCaseStudyFilesResponse;
  searchAcmCompetencies: AcmCompetenciesResponse;
  searchAcmProjectCompetencies: AcmProjectCompetenciesResponse;
  searchAcmProjects: AcmProjectsResponse;
  searchCaseStudies: CaseStudiesResponse;
  searchCompetencyGroups: CompetencyGroupsResponse;
  searchCompetencyGroupsWithAllCompetencies: CompetencyGroupsResponse;
  searchDemographicTemplates: DemographicTemplatesWithCountResponse;
  searchKpis: KpisResponse;
  searchMasterProductNarratives: ProductNarrativesResponse;
  searchMasterProducts: MasterProductsResponse;
  searchOrganizations: OrganizationsResponse;
  searchProductNarratives: ProductNarrativesResponse;
  searchProductSendSessions: ProductSessionsResponse;
  searchProductSends: ProductSendsResponse;
  searchProducts: ProductsResponse;
  searchProjects: ProjectsResponse;
  searchSelfRaters: SelfRatersResponse;
  searchStdProjectReports: SearchStdReportsResponse;
  searchStdProjectSessions: StdProjectSessionsResponse;
  searchStdProjects: StdProjectsResponse;
  searchStdSurveyProjects: StdProjectsResponse;
  searchSurveyProjects: ProjectsResponse;
  searchSurveys: SurveysResponse;
  searchTrashedAcmProjects: AcmProjectsResponse;
  searchTrashedCaseStudies: CaseStudiesResponse;
  searchTrashedStdProjects: StdProjectsResponse;
  selfRater: SelfRaterResponse;
  selfRaterRawScores360: Scalars['String'];
  standardSurveyDimensions: Array<StdDimensionResponse>;
  standardSurveyPage: StandardSurveyPageResponse;
  standardSurveyPages: Array<StandardSurveyPageResponse>;
  stdPageItems: Array<StdPageItemResponse>;
  stdPagesWithPageItems: Array<StandardSurveyPageWithItemsResponse>;
  stdProject: StdProjectResponse;
  stdProjectHeatmapReport: Array<HeatmapMatrixRowResponse>;
  stdProjectParticipantsReport: Array<StdReportParticipantsResponse>;
  stdProjectReport: StdReportResponse;
  stdProjectReportDetails: StdProjectReportDetailsResponse;
  stdProjectSettings?: Maybe<StdProjectSettingsResponse>;
  superAdminAdsAssessments: AdsAssessmentsResponse;
  superAdminOrganizations: OrganizationsResponse;
  survey: SurveyWithLocalesResponse;
  surveyCompetencies: Array<SurveyCompetencyWithItemsResponse>;
  timezones: Array<Scalars['String']>;
  trashedCompetencyGroups: CompetencyGroupsResponse;
  trashedDemographicTemplates: DemographicTemplatesWithCountResponse;
  trashedMasterProducts: MasterProductsResponse;
  trashedProducts: ProductsResponse;
  trashedSurveys: SurveysResponse;
};


export type QueryAcmCompetenciesArgs = {
  ids: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};


export type QueryAcmCompetencyArgs = {
  acmCompetencyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryAcmProjectArgs = {
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryAcmProjectCompetencyKpisArgs = {
  acmProjectCompetencyId: Scalars['String'];
  acmProjectId: Scalars['String'];
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryAcmProjectParticipantsArgs = {
  acmProjectId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryCaseStudyArgs = {
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryCaseStudyKpisArgs = {
  caseStudyId: Scalars['String'];
  competencyId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
};


export type QueryCheckStdSessionArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type QueryCheckSurveySessionArgs = {
  sessionToken: Scalars['String'];
};


export type QueryCompetenciesArgs = {
  competencyGroupId: Scalars['String'];
  organizationId: Scalars['String'];
  query: CompetenciesArgs;
};


export type QueryCompetencyGroupArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryCountStdSessionsWithTrashedOptionArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryDemographicTemplateArgs = {
  demographicTemplateId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryDemographicsArgs = {
  demographicTemplateId: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  organizationId: Scalars['String'];
};


export type QueryExportStdQuestionsAndAnswersToCsvArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryGenerateMyProductReportArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productSendId: Scalars['String'];
};


export type QueryIsProjectNameExistArgs = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryIsStdProjectNameExistArgs = {
  name: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryJoinRequestsArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QueryKpiArgs = {
  kpiId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QueryLastThreeJoinRequestsArgs = {
  organizationId: Scalars['String'];
};


export type QueryMasterProductArgs = {
  masterProductId: Scalars['String'];
};


export type QueryMasterProductIndicatorsArgs = {
  masterProductId: Scalars['String'];
};


export type QueryMasterProductNarrativeArgs = {
  _id: Scalars['String'];
  masterProductId: Scalars['String'];
};


export type QueryMembersAndInvitationsArgs = {
  organizationId: Scalars['String'];
};


export type QueryMyOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryNextCompetencyArgs = {
  sessionToken: Scalars['String'];
};


export type QueryNextStdSessionPageArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryOrganizationAdsAssessmentsArgs = {
  args: SearchAdsAssessmentsArgs;
  organizationId: Scalars['String'];
};


export type QueryOrganizationAssignableProductsArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QueryPrevCompetencyArgs = {
  sessionToken: Scalars['String'];
};


export type QueryPrevStdSessionPageArgs = {
  stdSessionInfo: StdSessionInfoArgs;
};


export type QueryProductArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryProductEmailReportsArgs = {
  args: ProductEmailReportsArgs;
  organizationId: Scalars['String'];
};


export type QueryProductIndicatorsArgs = {
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryProductNarrativeArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type QueryProductSendArgs = {
  organizationId: Scalars['String'];
  productSendId: Scalars['String'];
};


export type QueryProjectArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryProjectRawScores360Args = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryProjectSettingsArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryReport360DataArgs = {
  lang?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type QueryReport360PdfArgs = {
  lang?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type QueryRolesArgs = {
  organizationId: Scalars['String'];
};


export type QuerySearchAcmCaseFilesArgs = {
  args: SearchAcmCaseFileArgs;
  caseStudyId: Scalars['String'];
  organizationId: Scalars['String'];
};


export type QuerySearchAcmCompetenciesArgs = {
  args: SearchAcmCompetenciesArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchAcmProjectCompetenciesArgs = {
  acmProjectId: Scalars['String'];
  args: SearchAcmProjectCompetenciesArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchAcmProjectsArgs = {
  args: SearchAcmProjectsArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchCaseStudiesArgs = {
  args: SearchCaseStudiesArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchCompetencyGroupsArgs = {
  args: SearchCompetencyGroupArgs;
};


export type QuerySearchCompetencyGroupsWithAllCompetenciesArgs = {
  args: SearchCompetencyGroupArgs;
  localeCode?: InputMaybe<Scalars['String']>;
};


export type QuerySearchDemographicTemplatesArgs = {
  args: SearchDemographicTemplatesArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchKpisArgs = {
  args: SearchKpisArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchMasterProductNarrativesArgs = {
  args: SearchProductNarrativeArgs;
  masterProductId: Scalars['String'];
};


export type QuerySearchMasterProductsArgs = {
  args: SearchProductArgs;
};


export type QuerySearchOrganizationsArgs = {
  searchOrganizationsData: SearchOrganizationArgs;
};


export type QuerySearchProductNarrativesArgs = {
  args: SearchProductNarrativeArgs;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
};


export type QuerySearchProductSendSessionsArgs = {
  args: SearchProductSessionsArgs;
  organizationId: Scalars['String'];
  productId: Scalars['String'];
  productSendId: Scalars['String'];
};


export type QuerySearchProductSendsArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchProductsArgs = {
  args: SearchProductArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchProjectsArgs = {
  args: SearchProjectsArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchSelfRatersArgs = {
  args: SearchSelfRatersArgs;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QuerySearchStdProjectReportsArgs = {
  args: SearchStdReportsArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchStdProjectSessionsArgs = {
  args: SearchProjectSessionsArgs;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QuerySearchStdProjectsArgs = {
  args: SearchStdProjectsArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchStdSurveyProjectsArgs = {
  args: SearchStdProjectsArgs;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QuerySearchSurveyProjectsArgs = {
  args: SearchProjectsArgs;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QuerySearchSurveysArgs = {
  args: SearchSurveysArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchTrashedAcmProjectsArgs = {
  args: SearchAcmProjectsArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchTrashedCaseStudiesArgs = {
  args: SearchCaseStudiesArgs;
  organizationId: Scalars['String'];
};


export type QuerySearchTrashedStdProjectsArgs = {
  args: SearchStdProjectsArgs;
  organizationId: Scalars['String'];
};


export type QuerySelfRaterArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QuerySelfRaterRawScores360Args = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
  selfRaterId: Scalars['String'];
};


export type QueryStandardSurveyDimensionsArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryStandardSurveyPageArgs = {
  _id: Scalars['String'];
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryStandardSurveyPagesArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryStdPageItemsArgs = {
  organizationId: Scalars['String'];
  query: StdPageItemArgs;
  stdPageId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryStdPagesWithPageItemsArgs = {
  localeCodes: Array<Scalars['String']>;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryStdProjectArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryStdProjectHeatmapReportArgs = {
  allowPercentageHeatMap?: InputMaybe<Scalars['Boolean']>;
  dimensionsFilter?: InputMaybe<Array<Scalars['String']>>;
  drillDownBy?: InputMaybe<StdProjectReportDrillDownArgs>;
  dynamicDemographicsFilter?: InputMaybe<Array<StdProjectReportDynamicDemographicsArgs>>;
  filterBy?: InputMaybe<StdProjectReportFilterArgs>;
  organizationId: Scalars['String'];
  percentage?: InputMaybe<Scalars['Float']>;
  percentageHeatMapCutOff?: InputMaybe<Scalars['Float']>;
  projectId: Scalars['String'];
};


export type QueryStdProjectParticipantsReportArgs = {
  drillDownBy?: InputMaybe<StdProjectReportDrillDownArgs>;
  dynamicDemographicsFilter?: InputMaybe<Array<StdProjectReportDynamicDemographicsArgs>>;
  filterBy?: InputMaybe<StdProjectReportFilterArgs>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryStdProjectReportArgs = {
  dimensionsFilter?: InputMaybe<Array<Scalars['String']>>;
  drillDownBy?: InputMaybe<StdProjectReportDrillDownArgs>;
  dynamicDemographicsFilter?: InputMaybe<Array<StdProjectReportDynamicDemographicsArgs>>;
  filterBy?: InputMaybe<StdProjectReportFilterArgs>;
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryStdProjectReportDetailsArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QueryStdProjectSettingsArgs = {
  organizationId: Scalars['String'];
  projectId: Scalars['String'];
};


export type QuerySuperAdminAdsAssessmentsArgs = {
  args: SearchAdsAssessmentsArgs;
};


export type QuerySuperAdminOrganizationsArgs = {
  args: SearchSuperAdminOrganizationArgs;
};


export type QuerySurveyArgs = {
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
  withLocaleCodes?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySurveyCompetenciesArgs = {
  localeCodes: Array<Scalars['String']>;
  organizationId: Scalars['String'];
  surveyId: Scalars['String'];
};


export type QueryTrashedCompetencyGroupsArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QueryTrashedDemographicTemplatesArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QueryTrashedMasterProductsArgs = {
  args: PaginationArgs;
};


export type QueryTrashedProductsArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};


export type QueryTrashedSurveysArgs = {
  args: PaginationArgs;
  organizationId: Scalars['String'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type ResendVerificationCodeInput = {
  userId: Scalars['String'];
  verificationToken: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  resetPasswordCode: Scalars['Float'];
  userId: Scalars['String'];
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  _id: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type SearchAcmCaseFileArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchAcmCompetenciesArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchAcmProjectCompetenciesArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchAcmProjectsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchAdsAssessmentsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchCaseStudiesArgs = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchCompetencyGroupArgs = {
  limit: Scalars['Float'];
  organizationId: Scalars['String'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchDemographicTemplatesArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchKpisArgs = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type SearchOrganizationArgs = {
  limit: Scalars['Float'];
  name: Scalars['String'];
  page: Scalars['Float'];
};

export type SearchProductArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchProductNarrativeArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchProductSessionsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchProjectSessionsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type SearchProjectsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchSelfRaterResponse = {
  __typename?: 'SearchSelfRaterResponse';
  _id: Scalars['String'];
  completedParticipants?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  endDate: Scalars['DateTime'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  totalParticipants?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type SearchSelfRatersArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchStdProjectsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchStdReportResponse = {
  __typename?: 'SearchStdReportResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['String'];
  firstName: Scalars['String'];
  image: Scalars['String'];
  lastName: Scalars['String'];
  link: Scalars['String'];
  size: Scalars['String'];
  stdProjectId: Scalars['String'];
  stdProjectName: Scalars['String'];
  stdSurveyId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SearchStdReportsArgs = {
  createdById?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
  stdProjectIds?: InputMaybe<Array<Scalars['String']>>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};

export type SearchStdReportsResponse = {
  __typename?: 'SearchStdReportsResponse';
  docs: Array<SearchStdReportResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type SearchSuperAdminOrganizationArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SearchSurveysArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type SelectLocaleInput = {
  localeCode: Scalars['String'];
};

export type SelfRaterInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  participants: Array<SelfRaterParticipantInput>;
};

export type SelfRaterParticipantInput = {
  email: Scalars['String'];
  participantTypeId: Scalars['String'];
};

export type SelfRaterResponse = {
  __typename?: 'SelfRaterResponse';
  _id: Scalars['String'];
  email: Scalars['String'];
  endDate: Scalars['DateTime'];
  image?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  participants: Array<ParticipantResponse>;
  sendDate: Scalars['DateTime'];
};

export type SelfRatersResponse = {
  __typename?: 'SelfRatersResponse';
  docs: Array<SearchSelfRaterResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type SendProductsInput = {
  emailBody?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  productIds: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SendProductsToIndividualInput = {
  email: Scalars['String'];
  emailBody?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  productIds: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SendProjectInput = {
  endDate: Scalars['DateTime'];
  selfRaters: Array<SelfRaterInput>;
  sendDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type SendStdProjectInput = {
  emails?: InputMaybe<Array<Scalars['String']>>;
  endDate: Scalars['DateTime'];
  sendDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};

export type SessionSubItemResponse = {
  __typename?: 'SessionSubItemResponse';
  _id: Scalars['String'];
  questionDefinition?: Maybe<Scalars['String']>;
  questionTitle: Scalars['String'];
  responses: Array<SessionSubItemResponseResponse>;
  selectedResponseId?: Maybe<Scalars['String']>;
};

export type SessionSubItemResponseResponse = {
  __typename?: 'SessionSubItemResponseResponse';
  _id: Scalars['String'];
  text: Scalars['String'];
};

export type SetParticipantEmailTemplateInput = {
  _id: Scalars['String'];
  body: Scalars['String'];
  participantTypeId: Scalars['String'];
  subject: Scalars['String'];
};

export type SetParticipantTypeInput = {
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type SetProductIndicatorInput = {
  _id?: InputMaybe<Scalars['String']>;
  fromScore: Scalars['Float'];
  name: Scalars['String'];
  toScore: Scalars['Float'];
};

export type SetProjectSettingsInput = {
  globalBody?: InputMaybe<Scalars['String']>;
  globalSubject?: InputMaybe<Scalars['String']>;
  participantEmailTemplates?: InputMaybe<Array<SetParticipantEmailTemplateInput>>;
  participantTypes: Array<SetParticipantTypeInput>;
  restrictLogin?: InputMaybe<Scalars['Boolean']>;
  selfRaterBody?: InputMaybe<Scalars['String']>;
  selfRaterSubject?: InputMaybe<Scalars['String']>;
  unifyEmailForAll: Scalars['Boolean'];
};

export type SetStandardDimensionInput = {
  _id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  subDimensions?: InputMaybe<Array<SetSubStandardDimensionInput>>;
};

export type SetStandardDimensionsInput = {
  dimensions: Array<SetStandardDimensionInput>;
};

export type SetStdProjectSettingsInput = {
  allowPublicLink?: InputMaybe<Scalars['Boolean']>;
  globalBody: Scalars['String'];
  globalSubject: Scalars['String'];
  restrictLogin?: InputMaybe<Scalars['Boolean']>;
};

export type SetSubStandardDimensionInput = {
  _id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SigninInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SigninResponse = {
  __typename?: 'SigninResponse';
  accessToken?: Maybe<Scalars['String']>;
  isVerified: Scalars['Boolean'];
  refreshToken?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  verificationToken?: Maybe<Scalars['String']>;
};

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
};

export type SignupResponse = {
  __typename?: 'SignupResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
  userId: Scalars['String'];
  verificationToken: Scalars['String'];
};

export type StandardSurveyPageResponse = {
  __typename?: 'StandardSurveyPageResponse';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};

export type StandardSurveyPageWithItemsResponse = {
  __typename?: 'StandardSurveyPageWithItemsResponse';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  stdPageItems: Array<StdPageItemResponse>;
};

export type StdDimensionResponse = {
  __typename?: 'StdDimensionResponse';
  _id: Scalars['String'];
  name: Scalars['String'];
  subDimensions: Array<StdSubDimensionResponse>;
};

export type StdPageItemAnswerResponse = {
  __typename?: 'StdPageItemAnswerResponse';
  _id: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Array<LocaleResponse>>;
  value?: Maybe<Scalars['String']>;
};

export type StdPageItemArgs = {
  localeCodes: Array<Scalars['String']>;
};

export type StdPageItemQuestionResponse = {
  __typename?: 'StdPageItemQuestionResponse';
  _id: Scalars['String'];
  dimensionId?: Maybe<Scalars['String']>;
  isInverted?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  subDimensionId?: Maybe<Scalars['String']>;
  text?: Maybe<Array<LocaleResponse>>;
};

export type StdPageItemResponse = {
  __typename?: 'StdPageItemResponse';
  _id: Scalars['String'];
  answers?: Maybe<Array<StdPageItemAnswerResponse>>;
  createdAt: Scalars['DateTime'];
  dimensionId?: Maybe<Scalars['String']>;
  infoText?: Maybe<Array<LocaleResponse>>;
  order: Scalars['String'];
  questionTitle?: Maybe<Array<LocaleResponse>>;
  questions?: Maybe<Array<StdPageItemQuestionResponse>>;
  subDimensionId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StdProjectReportDetailsDimensionResponse = {
  __typename?: 'StdProjectReportDetailsDimensionResponse';
  _id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type StdProjectReportDetailsResponse = {
  __typename?: 'StdProjectReportDetailsResponse';
  completedParticipants?: Maybe<Scalars['Float']>;
  dimensions?: Maybe<Array<StdProjectReportDetailsDimensionResponse>>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  qualitativeItems?: Maybe<Scalars['Float']>;
  quantitativeItems?: Maybe<Scalars['Float']>;
  totalParticipants?: Maybe<Scalars['Float']>;
};

export type StdProjectReportDrillDownArgs = {
  dynamicKey?: InputMaybe<Scalars['String']>;
  staticKey?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type StdProjectReportDynamicDemographicsArgs = {
  demographicId: Scalars['String'];
  optionId: Scalars['String'];
};

export type StdProjectReportFilterArgs = {
  ageGroup?: InputMaybe<Array<Scalars['String']>>;
  businessUnit?: InputMaybe<Array<Scalars['String']>>;
  city?: InputMaybe<Array<Scalars['String']>>;
  custom1?: InputMaybe<Array<Scalars['String']>>;
  custom2?: InputMaybe<Array<Scalars['String']>>;
  custom3?: InputMaybe<Array<Scalars['String']>>;
  department?: InputMaybe<Array<Scalars['String']>>;
  experience?: InputMaybe<Array<Scalars['String']>>;
  gender?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<Array<Scalars['String']>>;
  seniority?: InputMaybe<Array<Scalars['String']>>;
  tenure?: InputMaybe<Array<Scalars['String']>>;
};

export type StdProjectResponse = {
  __typename?: 'StdProjectResponse';
  _id: Scalars['String'];
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  stdSurveyId: Scalars['String'];
  stdSurveyName: Scalars['String'];
  stdSurveyType: Scalars['String'];
  trashedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type StdProjectSessionResponse = {
  __typename?: 'StdProjectSessionResponse';
  _id: Scalars['String'];
  ageGroup?: Maybe<Scalars['String']>;
  businessUnit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  custom1?: Maybe<Scalars['String']>;
  custom2?: Maybe<Scalars['String']>;
  custom3?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  experience?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  seniority?: Maybe<Scalars['String']>;
  tenure?: Maybe<Scalars['String']>;
};

export type StdProjectSessionsResponse = {
  __typename?: 'StdProjectSessionsResponse';
  docs: Array<StdProjectSessionResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type StdProjectSettingsResponse = {
  __typename?: 'StdProjectSettingsResponse';
  _id: Scalars['String'];
  allowPublicLink?: Maybe<Scalars['Boolean']>;
  globalBody?: Maybe<Scalars['String']>;
  globalSubject?: Maybe<Scalars['String']>;
  restrictLogin?: Maybe<Scalars['Boolean']>;
};

export type StdProjectsResponse = {
  __typename?: 'StdProjectsResponse';
  docs: Array<StdProjectResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type StdReportDimensionGroupResponse = {
  __typename?: 'StdReportDimensionGroupResponse';
  dimensionId: Scalars['String'];
  dimensionName: Scalars['String'];
  questions: Array<StdReportQuestionResponse>;
};

export type StdReportDimensionResponse = {
  __typename?: 'StdReportDimensionResponse';
  _id: Scalars['String'];
  name: Scalars['String'];
};

export type StdReportDrillDownFrequencyResponse = {
  __typename?: 'StdReportDrillDownFrequencyResponse';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type StdReportDynamicDemographicOptionResponse = {
  __typename?: 'StdReportDynamicDemographicOptionResponse';
  _id: Scalars['String'];
  label: Scalars['String'];
};

export type StdReportDynamicDemographicResponse = {
  __typename?: 'StdReportDynamicDemographicResponse';
  _id: Scalars['String'];
  label: Scalars['String'];
  options: Array<StdReportDynamicDemographicOptionResponse>;
};

export type StdReportFilterResponse = {
  __typename?: 'StdReportFilterResponse';
  ageGroup?: Maybe<Array<Scalars['String']>>;
  businessUnit?: Maybe<Array<Scalars['String']>>;
  city?: Maybe<Array<Scalars['String']>>;
  custom1?: Maybe<Array<Scalars['String']>>;
  custom2?: Maybe<Array<Scalars['String']>>;
  custom3?: Maybe<Array<Scalars['String']>>;
  department?: Maybe<Array<Scalars['String']>>;
  experience?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<Array<Scalars['String']>>;
  seniority?: Maybe<Array<Scalars['String']>>;
  tenure?: Maybe<Array<Scalars['String']>>;
};

export type StdReportParticipantsResponse = {
  __typename?: 'StdReportParticipantsResponse';
  completedCount?: Maybe<Scalars['Float']>;
  drillDownValue?: Maybe<Scalars['String']>;
  totalCount?: Maybe<Scalars['Float']>;
};

export type StdReportQuestionAnswerResponse = {
  __typename?: 'StdReportQuestionAnswerResponse';
  _id: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  drilledDowns?: Maybe<Array<StdReportDrillDownFrequencyResponse>>;
  score?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  totalFrequency?: Maybe<Scalars['Float']>;
};

export type StdReportQuestionResponse = {
  __typename?: 'StdReportQuestionResponse';
  _id: Scalars['String'];
  answers: Array<StdReportQuestionAnswerResponse>;
  label: Scalars['String'];
};

export type StdReportResponse = {
  __typename?: 'StdReportResponse';
  dimensionGroups: Array<StdReportDimensionGroupResponse>;
  dimensions: Array<StdReportDimensionResponse>;
  dynamicDemographics?: Maybe<Array<StdReportDynamicDemographicResponse>>;
  filterFields?: Maybe<StdReportFilterResponse>;
  sessionsCount: Scalars['Float'];
};

export type StdSelectLocaleInput = {
  localeCode: Scalars['String'];
};

export type StdSessionDemographicAnswerResponse = {
  __typename?: 'StdSessionDemographicAnswerResponse';
  _id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type StdSessionDemographicResponse = {
  __typename?: 'StdSessionDemographicResponse';
  _id: Scalars['String'];
  answerId?: Maybe<Scalars['String']>;
  answerIds?: Maybe<Array<Scalars['String']>>;
  answerText?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<StdSessionDemographicAnswerResponse>>;
  customErrorMessage?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  multipleChoicesValidationType?: Maybe<Scalars['String']>;
  multipleChoicesValidationValue?: Maybe<Scalars['Float']>;
  order: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  shortAnswerValidation?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type StdSessionInfoArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  stdProjectId?: InputMaybe<Scalars['String']>;
  stdPublicSessionId?: InputMaybe<Scalars['String']>;
};

export type StdSessionInfoPageDataResponse = {
  __typename?: 'StdSessionInfoPageDataResponse';
  infoPageContent: Scalars['String'];
  isPolicyApproved?: Maybe<Scalars['Boolean']>;
  isSurveyConsentRequired?: Maybe<Scalars['Boolean']>;
  policyText: Scalars['String'];
};

export type StdSessionPageDataResponse = {
  __typename?: 'StdSessionPageDataResponse';
  nextStdPageId?: Maybe<Scalars['String']>;
  prevStdPageId?: Maybe<Scalars['String']>;
  progressPercentage: Scalars['Float'];
  stdPageId: Scalars['String'];
  stdPageItems: Array<StdSessionPageItemResponse>;
};

export type StdSessionPageItemAnswerResponse = {
  __typename?: 'StdSessionPageItemAnswerResponse';
  _id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type StdSessionPageItemLinearQuestionResponse = {
  __typename?: 'StdSessionPageItemLinearQuestionResponse';
  _id: Scalars['String'];
  order: Scalars['String'];
  questionTitle?: Maybe<Scalars['String']>;
  selectedAnswerId?: Maybe<Scalars['String']>;
};

export type StdSessionPageItemResponse = {
  __typename?: 'StdSessionPageItemResponse';
  _id: Scalars['String'];
  answers: Array<StdSessionPageItemAnswerResponse>;
  infoText?: Maybe<Scalars['String']>;
  linearQuestions: Array<StdSessionPageItemLinearQuestionResponse>;
  openEndedAnswer?: Maybe<Scalars['String']>;
  openEndedQuestion?: Maybe<Scalars['String']>;
  order: Scalars['String'];
  type: Scalars['String'];
};

export type StdSessionSelectLocaleDataResponse = {
  __typename?: 'StdSessionSelectLocaleDataResponse';
  surveyDefaultLocale: Scalars['String'];
  surveyLocaleCodes: Array<Scalars['String']>;
};

export type StdSubDimensionResponse = {
  __typename?: 'StdSubDimensionResponse';
  _id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SuperAdminOrganizationResponse = {
  __typename?: 'SuperAdminOrganizationResponse';
  _id: Scalars['String'];
  active: Scalars['Boolean'];
  consumedUnits?: Maybe<Scalars['Float']>;
  domain?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  units?: Maybe<Scalars['Float']>;
};

export type SurveyCompetencyNarrativeResponse = {
  __typename?: 'SurveyCompetencyNarrativeResponse';
  text?: Maybe<Array<LocaleResponse>>;
  type: Scalars['String'];
};

export type SurveyCompetencyResponse = {
  __typename?: 'SurveyCompetencyResponse';
  _id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Array<LocaleResponse>>;
  name?: Maybe<Array<LocaleResponse>>;
  narratives?: Maybe<Array<SurveyCompetencyNarrativeResponse>>;
  order: Scalars['String'];
  originalCompetencyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyCompetencyWithItemsResponse = {
  __typename?: 'SurveyCompetencyWithItemsResponse';
  _id: Scalars['String'];
  competenciesItems?: Maybe<Array<CompetencyItemResponse>>;
  createdAt: Scalars['DateTime'];
  definition?: Maybe<Array<LocaleResponse>>;
  name?: Maybe<Array<LocaleResponse>>;
  narratives?: Maybe<Array<SurveyCompetencyNarrativeResponse>>;
  order: Scalars['String'];
  originalCompetencyId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  _id: Scalars['String'];
  allowBack?: Maybe<Scalars['Boolean']>;
  allowDemographics?: Maybe<Scalars['Boolean']>;
  consentRequired?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  demographicTemplateId?: Maybe<Scalars['String']>;
  demographicTemplateName?: Maybe<Scalars['String']>;
  horizontalImage?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  localeCodes: Array<Scalars['String']>;
  masterSurveyUnits?: Maybe<Scalars['Float']>;
  mustAnswerAll?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  responsesLayout?: Maybe<Scalars['String']>;
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  verticalImage?: Maybe<Scalars['String']>;
};

export type SurveySessionCompetencyDataResponse = {
  __typename?: 'SurveySessionCompetencyDataResponse';
  comment?: Maybe<Scalars['String']>;
  competenciesItems: Array<SurveySessionCompetencyItemResponse>;
  competencyDefinition: Scalars['String'];
  competencyId: Scalars['String'];
  competencyName: Scalars['String'];
  nextCompetencyId?: Maybe<Scalars['String']>;
  prevCompetencyId?: Maybe<Scalars['String']>;
  progressPercentage: Scalars['Float'];
};

export type SurveySessionCompetencyItemResponse = {
  __typename?: 'SurveySessionCompetencyItemResponse';
  _id: Scalars['String'];
  order: Scalars['String'];
  questionTitle: Scalars['String'];
  responses: Array<SurveySessionCompetencyItemResponseResponse>;
  selectedResponseId?: Maybe<Scalars['String']>;
  subItem?: Maybe<SessionSubItemResponse>;
};

export type SurveySessionCompetencyItemResponseResponse = {
  __typename?: 'SurveySessionCompetencyItemResponseResponse';
  _id: Scalars['String'];
  text: Scalars['String'];
};

export type SurveySessionInfoPageDataResponse = {
  __typename?: 'SurveySessionInfoPageDataResponse';
  infoPageContent: Scalars['String'];
  isPolicyApproved?: Maybe<Scalars['Boolean']>;
  isSurveyConsentRequired?: Maybe<Scalars['Boolean']>;
  policyText: Scalars['String'];
};

export type SurveySessionSelectLocaleDataResponse = {
  __typename?: 'SurveySessionSelectLocaleDataResponse';
  surveyDefaultLocale: Scalars['String'];
  surveyLocaleCodes: Array<Scalars['String']>;
};

export type SurveyWithLocalesResponse = {
  __typename?: 'SurveyWithLocalesResponse';
  _id: Scalars['String'];
  allowBack?: Maybe<Scalars['Boolean']>;
  allowDemographics?: Maybe<Scalars['Boolean']>;
  consentRequired?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  defaultLocaleCode: Scalars['String'];
  demographicTemplateId?: Maybe<Scalars['String']>;
  demographicTemplateName?: Maybe<Scalars['String']>;
  horizontalImage?: Maybe<Scalars['String']>;
  infoPageContent?: Maybe<Array<LocaleResponse>>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  localeCodes: Array<Scalars['String']>;
  masterSurveyUnits?: Maybe<Scalars['Float']>;
  mustAnswerAll?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  policyText?: Maybe<Array<LocaleResponse>>;
  primaryColor?: Maybe<Scalars['String']>;
  responsesLayout?: Maybe<Scalars['String']>;
  trashedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  verticalImage?: Maybe<Scalars['String']>;
};

export type SurveysResponse = {
  __typename?: 'SurveysResponse';
  docs: Array<SurveyResponse>;
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Float'];
  nextPage?: Maybe<Scalars['Float']>;
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  prevPage?: Maybe<Scalars['Float']>;
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type UpdateAcmCaseStudyFileInput = {
  accessibleTo?: InputMaybe<Array<Scalars['String']>>;
  localeCode?: InputMaybe<Scalars['String']>;
};

export type UpdateAcmCompetencyInput = {
  name: Scalars['String'];
};

export type UpdateAcmProjectCompetencyExerciseInput = {
  _id?: InputMaybe<Scalars['String']>;
  caseStudyId: Scalars['String'];
  kpiIds?: InputMaybe<Array<Scalars['String']>>;
  weightPercentage: Scalars['Float'];
};

export type UpdateAcmProjectCompetencyInput = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  definition?: InputMaybe<Scalars['String']>;
  exercises?: InputMaybe<Array<UpdateAcmProjectCompetencyExerciseInput>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateAcmProjectInput = {
  companyName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  numOfDays?: InputMaybe<Scalars['Float']>;
  purpose?: InputMaybe<Scalars['String']>;
  scoringScale?: InputMaybe<Array<CreateAcmProjectScoringScaleInput>>;
};

export type UpdateAcmProjectParticipantInput = {
  _id: Scalars['String'];
  assessorEmails?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type UpdateAcmProjectParticipantsInput = {
  participants: Array<UpdateAcmProjectParticipantInput>;
};

export type UpdateAnswersInput = {
  _id: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateCaseStudyInput = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  maxCandidates?: InputMaybe<Scalars['Float']>;
  minCandidates?: InputMaybe<Scalars['Float']>;
  performingDuration?: InputMaybe<Scalars['String']>;
  preparationDuration?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateCompetencyGroupInput = {
  _id: Scalars['String'];
  defaultLocaleCode: Scalars['String'];
  definition: Scalars['String'];
  localeCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type UpdateCompetencyItemInput = {
  _id: Scalars['String'];
  competencyId: Scalars['String'];
  localeCode: Scalars['String'];
  measure?: InputMaybe<Scalars['String']>;
  questionTitle: Scalars['String'];
  responseStatements?: InputMaybe<Array<UpdateCompetencyItemResponseStatementInput>>;
  responses: Array<UpdateCompetencyItemResponseInput>;
  responsesLogic?: InputMaybe<Array<UpdateCompetencyItemResponseLogicInput>>;
};

export type UpdateCompetencyItemOrderInput = {
  _id: Scalars['String'];
  competencyId: Scalars['String'];
  order: Scalars['String'];
};

export type UpdateCompetencyItemResponseInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type UpdateCompetencyItemResponseLogicInput = {
  _id?: InputMaybe<Scalars['String']>;
  responseId: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateCompetencyItemResponseLogicResponseSubItemInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type UpdateCompetencyItemResponseLogicSubItemInput = {
  _id: Scalars['String'];
  competencyId: Scalars['String'];
  competencyItemId: Scalars['String'];
  localeCode: Scalars['String'];
  questionDefinition: Scalars['String'];
  questionTitle: Scalars['String'];
  responses: Array<UpdateCompetencyItemResponseLogicResponseSubItemInput>;
};

export type UpdateCompetencyItemResponseStatementInput = {
  _id?: InputMaybe<Scalars['String']>;
  responseIds: Array<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateCompetencyLocaleInput = {
  competencyId: Scalars['String'];
  definition?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  narratives?: InputMaybe<Array<UpdateNarrativeInput>>;
};

export type UpdateDemographicInput = {
  answers: Array<UpdateAnswersInput>;
  customErrorMessage?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  localeCode: Scalars['String'];
  multipleChoicesValidationType?: InputMaybe<Scalars['String']>;
  multipleChoicesValidationValue?: InputMaybe<Scalars['Float']>;
  required?: InputMaybe<Scalars['Boolean']>;
  shortAnswerValidation?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateDemographicTemplateInput = {
  defaultLocaleCode?: InputMaybe<Scalars['String']>;
  localeCodes?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateKpiInput = {
  competencyIds?: InputMaybe<Array<Scalars['String']>>;
  localeCodes?: InputMaybe<Array<Scalars['String']>>;
  negativeStatement?: InputMaybe<Array<LocaleEntryInput>>;
  notes?: InputMaybe<Array<UpdateKpiNoteInput>>;
  positiveStatement?: InputMaybe<Array<LocaleEntryInput>>;
  score1Narratives?: InputMaybe<Array<UpdateKpiScoreNarrativeInput>>;
  score1NarrativesStatus?: InputMaybe<Scalars['String']>;
  score2Narratives?: InputMaybe<Array<UpdateKpiScoreNarrativeInput>>;
  score2NarrativesStatus?: InputMaybe<Scalars['String']>;
  score3Narratives?: InputMaybe<Array<UpdateKpiScoreNarrativeInput>>;
  score3NarrativesStatus?: InputMaybe<Scalars['String']>;
  score4Narratives?: InputMaybe<Array<UpdateKpiScoreNarrativeInput>>;
  score4NarrativesStatus?: InputMaybe<Scalars['String']>;
  score5Narratives?: InputMaybe<Array<UpdateKpiScoreNarrativeInput>>;
  score5NarrativesStatus?: InputMaybe<Scalars['String']>;
};

export type UpdateKpiNoteInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  text: Array<LocaleEntryInput>;
};

export type UpdateKpiScoreNarrativeInput = {
  text?: InputMaybe<Array<LocaleEntryInput>>;
};

export type UpdateMasterProductAssessmentInput = {
  _id?: InputMaybe<Scalars['String']>;
  adsAssessmentName: Scalars['String'];
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type UpdateMasterProductInput = {
  assessments: Array<UpdateMasterProductAssessmentInput>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Scalars['Float']>;
};

export type UpdateMemberOrInvitationInput = {
  _id: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateMembersOrInvitationsInput = {
  entries: Array<UpdateMemberOrInvitationInput>;
};

export type UpdateMyProfileInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateNarrativeInput = {
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateOrganizationAccessInput = {
  assessmentModule?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['String'];
  surveyModule?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateOrganizationInput = {
  adsApiKey?: InputMaybe<Scalars['String']>;
  domain?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateProductAssessmentInput = {
  _id?: InputMaybe<Scalars['String']>;
  adsAssessmentName: Scalars['String'];
  assessmentId: Scalars['String'];
  assessmentName: Scalars['String'];
};

export type UpdateProductInput = {
  assessments: Array<UpdateProductAssessmentInput>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProductNarrativeInput = {
  content?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<Scalars['String']>;
  indicatorId?: InputMaybe<Scalars['String']>;
  langCode?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateProjectSelfRaterDateInput = {
  endDate: Scalars['DateTime'];
};

export type UpdateSelfRaterParticipantInput = {
  email?: InputMaybe<Scalars['String']>;
  participantId: Scalars['String'];
  participantTypeId?: InputMaybe<Scalars['String']>;
};

export type UpdateStandardSurveyPageInput = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type UpdateStdPageItemAnswerInput = {
  _id?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateStdPageItemInput = {
  _id: Scalars['String'];
  answers?: InputMaybe<Array<UpdateStdPageItemAnswerInput>>;
  dimensionId?: InputMaybe<Scalars['String']>;
  infoText?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
  questionTitle?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<UpdateStdPageItemQuestionInput>>;
  subDimensionId?: InputMaybe<Scalars['String']>;
};

export type UpdateStdPageItemOrderInput = {
  _id: Scalars['String'];
  order: Scalars['String'];
};

export type UpdateStdPageItemQuestionInput = {
  _id?: InputMaybe<Scalars['String']>;
  dimensionId?: InputMaybe<Scalars['String']>;
  isInverted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['String']>;
  subDimensionId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type UpdateStdProjectInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateStdProjectSessionInput = {
  ageGroup?: InputMaybe<Scalars['String']>;
  businessUnit?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  custom1?: InputMaybe<Scalars['String']>;
  custom2?: InputMaybe<Scalars['String']>;
  custom3?: InputMaybe<Scalars['String']>;
  department?: InputMaybe<Scalars['String']>;
  experience?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  seniority?: InputMaybe<Scalars['String']>;
  tenure?: InputMaybe<Scalars['String']>;
};

export type UpdateSurveyCompetencyItemInput = {
  _id: Scalars['String'];
  localeCode: Scalars['String'];
  measure?: InputMaybe<Scalars['String']>;
  questionTitle: Scalars['String'];
  responseStatements?: InputMaybe<Array<UpdateSurveyCompetencyItemResponseStatementInput>>;
  responses: Array<UpdateSurveyCompetencyItemResponseInput>;
  responsesLogic?: InputMaybe<Array<UpdateSurveyCompetencyItemResponseLogicInput>>;
  surveyCompetencyId: Scalars['String'];
};

export type UpdateSurveyCompetencyItemOrderInput = {
  _id: Scalars['String'];
  order: Scalars['String'];
};

export type UpdateSurveyCompetencyItemResponseInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type UpdateSurveyCompetencyItemResponseLogicInput = {
  _id?: InputMaybe<Scalars['String']>;
  responseId: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateSurveyCompetencyItemResponseLogicResponseSubItemInput = {
  _id: Scalars['String'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export type UpdateSurveyCompetencyItemResponseLogicSubItemInput = {
  _id: Scalars['String'];
  competencyItemId: Scalars['String'];
  localeCode: Scalars['String'];
  questionDefinition: Scalars['String'];
  questionTitle: Scalars['String'];
  responses: Array<UpdateSurveyCompetencyItemResponseLogicResponseSubItemInput>;
  surveyCompetencyId: Scalars['String'];
};

export type UpdateSurveyCompetencyItemResponseStatementInput = {
  _id?: InputMaybe<Scalars['String']>;
  responseIds: Array<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateSurveyCompetencyLocaleInput = {
  definition?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  narratives?: InputMaybe<Array<UpdateSurveyCompetencyNarrativeInput>>;
  surveyCompetencyId: Scalars['String'];
};

export type UpdateSurveyCompetencyNarrativeInput = {
  text?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type UpdateSurveyInput = {
  allowBack?: InputMaybe<Scalars['Boolean']>;
  allowDemographics?: InputMaybe<Scalars['Boolean']>;
  consentRequired?: InputMaybe<Scalars['Boolean']>;
  defaultLocaleCode?: InputMaybe<Scalars['String']>;
  demographicTemplateId?: InputMaybe<Scalars['String']>;
  localeCodes?: InputMaybe<Array<Scalars['String']>>;
  masterSurveyUnits?: InputMaybe<Scalars['Float']>;
  mustAnswerAll?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  primaryColor?: InputMaybe<Scalars['String']>;
  responsesLayout?: InputMaybe<Scalars['String']>;
};

export type UpdateSurveyLocaleInput = {
  infoPageContent?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
  policyText?: InputMaybe<Scalars['String']>;
  withLocaleCodes: Array<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  _id: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  superAdminOrganizationId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type VerifyInput = {
  userId: Scalars['String'];
  verificationCode: Scalars['Float'];
  verificationToken: Scalars['String'];
};
