import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

/**
 * shared service used for redirect with query params
 *
 * @export
 * @class RedirectService
 */
@Injectable()
export class RedirectService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  //  navigate to url with params or redirect url

  getRoute(): Subscription {
    return this.activatedRoute.queryParams
      .pipe(first())
      .subscribe((params) => this.redirect(params.redirectURL));
  }

  redirect(redirectURL: string): void {
    if (redirectURL && redirectURL.includes('?')) {
      const params = this.getQueryParams(redirectURL);
      this.router.navigate([redirectURL.split('?')[0]], params);
    } else {
      this.router.navigate([redirectURL || '/setup/organizations']);
    }
  }

  // method takes queryParams as string and convert it to object to use in navigation in redirect method

  private getQueryParams(locationSearch: string): any {
    const params: { [key: string]: string } = {};
    if (locationSearch) {
      locationSearch = locationSearch.split('?')[1];
      const split = locationSearch.split('&');
      for (const iterator of split) {
        const propName = iterator.split('=')[0];
        const propValue = iterator.split('=')[1];
        params[propName] = propValue;
      }
    }
    return { queryParams: { ...params } };
  }
}
