import { Injectable } from '@angular/core';

@Injectable()
export class DatesService {
  constructor() {}

  public dateConverter(date: string | number, lastSecond?: boolean): Date {
    return this._dateConverter(date, lastSecond);
  }

  public parseToCalendar(date: Date): string {
    return this._parseToCalendar(date);
  }

  public parseToDate(dateString: string): Date {
    return this._parseToDate(dateString);
  }

  private _dateConverter(date: string | number, lastSecond?: boolean): Date {
    const d = new Date(date);
    const now = new Date().toDateString();
    if (now === d.toDateString()) {
      return new Date(
        `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${
          lastSecond ? '23:59:59' : new Date().toTimeString().split(' ')[0]
        }`
      ); // GMT
    }
    return new Date(
      `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${
        lastSecond ? '23:59:59' : ''
      }`
    );
  }

  private _parseToCalendar(date: Date): string {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  }

  private _parseToDate(dateString: string): Date {
    const dateParts = dateString.split('/');
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
  }
}
