import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromActions from '../store/actions';

@Injectable()
export class TimezonesGuard implements CanActivate {
  constructor(private store: Store<fromStore.IRootState>) {}
  canActivate(): Observable<UrlTree | boolean> {
    return combineLatest([
      this.store.pipe(select(fromStore.getTimeZonesSelectTotal)),
      this.store.pipe(select(fromStore.getTimeZonesPending)),
    ]).pipe(
      filter(([total, pending]) => !pending),
      map(([total, pending]) => {
        if (!total) {
          this.store.dispatch(fromActions.loadTimeZones());
        }
        return true;
      })
    );
  }
}
