import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import * as fromContainers from './containers';
import * as fromGuards from 'src/app/guards';

const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

const routes: Routes = [
  {
    path: 'style-guide',
    loadChildren: () =>
      import('./views/style-guide/style-guide.module').then(
        (m) => m.StyleGuideModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'setup',
    loadChildren: () =>
      import('./views/setup/setup.module').then((m) => m.SetupModule),
    canActivate: [fromGuards.AuthGuardService],
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./views/organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
    canActivate: [fromGuards.AuthGuardService, fromGuards.SystemLanguagesGuard],
  },

  {
    path: 'invitation',
    loadChildren: () =>
      import(
        './views/join-organization-via-invitation/join-organization-via-invitation.module'
      ).then((m) => m.JoinOrganizationViaInvitationModule),
    canActivate: [fromGuards.AuthGuardService],
  },
  {
    path: 'survey-session',
    loadChildren: () =>
      import('./views/survey-session/survey-session.module').then(
        (m) => m.SurveySessionModule
      ),
    canActivate: [fromGuards.SystemLanguagesGuard],
  },
  {
    path: 'std-session',
    loadChildren: () =>
      import('./views/std-survey-session/std-survey-session.module').then(
        (m) => m.StdSurveySessionModule
      ),
    canActivate: [fromGuards.SystemLanguagesGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () =>
      import('./views/super-admin/super-admin.module').then(
        (m) => m.SuperAdminModule
      ),
    canActivate: [fromGuards.AuthGuardService, fromGuards.SystemLanguagesGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./views/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./views/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [fromGuards.AuthGuardService],
    data: {
      moduleName: 'PROFILE',
    },
  },
  {
    path: '404',
    component: fromContainers.NotFoundComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./views/about/about.module').then((m) => m.AboutModule),
    canActivate: [fromGuards.CloseAuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
