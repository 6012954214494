<button type="button" class="back" (click)="openDialog()">
  <i class="ph-arrow-left"></i>
</button>
<div class="auth-wrapper">
  <div class="auth">
    <img
      src="assets/hub/logo.svg"
      class="auth__logo"
      role="img"
      alt="Assess Talent Management Logo"
      aria-label="Assess Talent Management Logo"
    />

    <p class="title">
      No Problem, <br />
      Enter your email to reset your password
    </p>

    <!-- <div class="auth__tab">
      <a class="auth__tab__button auth__tab__button--selected">Login</a>
      <a
        class="auth__tab__button"
        [routerLink]="['/auth/signup']"
        queryParamsHandling="merge"
      >
        Create Account
      </a>
    </div> -->

    <app-forget-password-form
      (submitted)="onSubmit($event)"
      [forgetPasswordPending]="(forgetPasswordPending$ | async) ? true : false"
      [serverError]="forgetPasswordError$ | async"
    ></app-forget-password-form>
  </div>
</div>
