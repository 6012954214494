import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarService } from 'src/app/shared-modules/stateless/snack-bar/services';

import { MyOrganizationsService } from '../../services';
import * as fromActions from '../actions';

@Injectable()
export class MyOrganizationsEffects {
  constructor(
    private actions$: Actions,
    private myOrganizationsService: MyOrganizationsService,
    private snackBar: SnackBarService
  ) {}

  // loadMyOrganizations Effects
  loadMyOrganizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMyOrganizations),
      switchMap(() =>
        this.myOrganizationsService.loadMyOrganizations().pipe(
          map((result) =>
            fromActions.loadMyOrganizationsSuccess({ payload: result })
          ),
          catchError((error) =>
            of(fromActions.loadMyOrganizationsFailure({ payload: error }))
          )
        )
      )
    )
  );

  loadMyOrganizationsFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.loadMyOrganizationsFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to load my organizations, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  // createOrganization Effects
  createOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createOrganization),
      switchMap(({ payload }) =>
        this.myOrganizationsService
          .createOrganization(payload.input, payload.image)
          .pipe(
            map((result) =>
              fromActions.createOrganizationSuccess({ payload: result })
            ),
            catchError((error) =>
              of(fromActions.createOrganizationFailure({ payload: error }))
            )
          )
      )
    )
  );

  createOrganizationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.createOrganizationSuccess),
        tap(({ payload }) => {
          this.snackBar.open(
            `Organization ${payload?.name} is created successfully`,
            'SUCCESS',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );

  createOrganizationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromActions.createOrganizationFailure),
        tap(({ payload }) => {
          this.snackBar.open(
            `Failed to create organization, ${payload?.message}`,
            'FAILURE',
            3000
          );
        })
      );
    },
    { dispatch: false }
  );
}
