import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromTypes from 'src/types';

import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';

@Injectable()
export class OrganizationGuard implements CanActivate {
  constructor(private store: Store<fromStore.IRootState>) {}
  canActivate(): Observable<UrlTree | boolean> {
    return combineLatest([
      this.store.pipe(select(fromStore.getAuthCurrentUser)),
      this.store.pipe(select(fromStore.getOrganizationDetail)),
      this.store.pipe(select(fromStore.getParamOrganizationId)),
    ]).pipe(
      filter(([user, detail, organizationId]) => !!user && !!organizationId),
      map(
        ([user, detail, organizationId]: [
          fromTypes.UserResponse | undefined,
          fromTypes.MyOrganizationWithJobTitleResponse | undefined,
          string
        ]) => {
          if (!detail || organizationId !== detail._id) {
            this.store.dispatch(
              fromActions.loadOrganization({ payload: { organizationId } })
            );
          }
          return true;
        }
      )
    );
  }
}
