import { filter } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, zip, combineLatest } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as fromStore from 'src/app/store';
import * as fromActions from 'src/app/store/actions';
import { MatDialog } from '@angular/material/dialog';
import { SignupComponent } from '../../../signup/containers';
import { AnimationOptions } from 'ngx-lottie';

@UntilDestroy()
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, AfterViewInit {
  userEmail$: Observable<string | undefined>;
  verifyPending$: Observable<boolean>;
  resendPending$: Observable<boolean>;
  verifyError$: Observable<Error | undefined>;

  @ViewChild('resendButton') resendButton: ElementRef;

  counter = 60;
  userId: string;
  verificationToken: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<fromStore.IRootState>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.userEmail$ = this.store.pipe(select(fromStore.getAuthTempEmail));
    combineLatest([
      this.store.pipe(select(fromStore.getAuthUserId)),
      this.store.pipe(select(fromStore.getAuthVerificationToken)),
    ])
      .pipe(
        untilDestroyed(this),
        filter(([userId, verificationToken]) => !!userId && !!verificationToken)
      )
      .subscribe(([userId, verificationToken]) => {
        // console.log('{commit}', userId, verificationToken);
        this.userId = userId as string;
        this.verificationToken = verificationToken as string;
      });
    // this.activatedRoute.queryParams.subscribe((queryParams) => {
    //   if (queryParams && queryParams.verificationCode && queryParams.email) {
    //     this.store.dispatch(
    //       fromActions.verify({
    //         payload: {
    //           email: queryParams.email,
    //           verificationCode: queryParams.verificationCode,
    //         },
    //       })
    //     );
    //   }
    // });
    // this.verifying$ = this.store.pipe(select(fromStore.getVerifyPending));
    this.verifyPending$ = this.store.pipe(
      select(fromStore.getAuthVerifyPending)
    );
    this.verifyError$ = this.store.pipe(select(fromStore.getAuthVerifyError));
    this.resendPending$ = this.store.pipe(
      select(fromStore.getAuthResendVerificationPending)
    );
  }

  ngAfterViewInit(): void {
    this.count();
  }

  onSubmit(verificationCode: number) {
    this.store.dispatch(
      fromActions.verifyAccount({
        payload: {
          userId: this.userId,
          verificationToken: this.verificationToken,
          verificationCode,
        },
      })
    );
  }

  resendVerification() {
    if (this.counter === 60) {
      this.count();
      this.store.dispatch(
        fromStore.resendVerification({
          payload: {
            userId: this.userId,
            verificationToken: this.verificationToken,
          },
        })
      );
    }
  }

  openDialog() {
    this.dialog.closeAll();
    this.dialog.open(SignupComponent);
  }

  private count() {
    this.resendButton.nativeElement.setAttribute('class', 'disabled');
    const that = this;
    const timer = setInterval(function () {
      (that.counter as number) -= 1;
      // console.log(that.counter);
      if (that.counter === 0) {
        clearInterval(timer);
        that.counter = 60;
      }
    }, 1000);
  }
}
