import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromModels from '../../models';
import * as fromAuthReducer from './auth.reducer';
import * as fromSystemLanguagesReducer from './system-languages.reducer';
import * as fromTimeZonesReducer from './timezones.reducer';
import * as fromMyOrganizationsReducer from './my-organizations.reducer';
import * as fromOrganizationReducer from './organization.reducer';

export * from './clear-state.reducer';

export interface IRootState {
  auth: fromAuthReducer.IAuthState;
  router: fromRouter.RouterReducerState<fromModels.RouterStateUrl>;
  systemLanguages: fromSystemLanguagesReducer.ISystemLanguagesState;
  timeZones: fromTimeZonesReducer.ITimeZonesState;
  myOrganizations: fromMyOrganizationsReducer.IMyOrganizationsState;
  organization: fromOrganizationReducer.IOrganizationState;
}

export const reducers: ActionReducerMap<IRootState, Action> = {
  auth: fromAuthReducer.reducer,
  router: fromRouter.routerReducer,
  systemLanguages: fromSystemLanguagesReducer.reducer,
  timeZones: fromTimeZonesReducer.reducer,
  myOrganizations: fromMyOrganizationsReducer.reducer,
  organization: fromOrganizationReducer.reducer,
};

export const getAuthState =
  createFeatureSelector<fromAuthReducer.IAuthState>('auth');

export const getRouterState =
  createFeatureSelector<fromModels.RouterStateUrl>('router');

export const getSystemLanguagesState =
  createFeatureSelector<fromSystemLanguagesReducer.ISystemLanguagesState>(
    'systemLanguages'
  );

export const getTimeZonesState =
  createFeatureSelector<fromTimeZonesReducer.ITimeZonesState>('timeZones');

export const getMyOrganizationsState =
  createFeatureSelector<fromMyOrganizationsReducer.IMyOrganizationsState>(
    'myOrganizations'
  );

export const getOrganizationState =
  createFeatureSelector<fromOrganizationReducer.IOrganizationState>(
    'organization'
  );
