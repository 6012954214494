import * as fromTypes from 'src/types';

export const LANGUAGES: fromTypes.LanguageResponse[] = [
  {
    code: 'en',
    name: 'English',
    nativeName: 'English',
    dir: 'LTR',
    __typename: 'LanguageResponse',
  },
  {
    code: 'ar',
    name: 'Arabic',
    nativeName: 'العربية',
    dir: 'RTL',
    __typename: 'LanguageResponse',
  },
  {
    code: 'zh',
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
    dir: 'LTR',
    __typename: 'LanguageResponse',
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'français, langue française',
    dir: 'LTR',
    __typename: 'LanguageResponse',
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch',
    dir: 'LTR',
    __typename: 'LanguageResponse',
  },
  {
    code: 'es',
    name: 'Spanish; Castilian',
    nativeName: 'español, castellano',
    dir: 'LTR',
    __typename: 'LanguageResponse',
  },
];
