import { Action, createReducer, on } from '@ngrx/store';

import * as fromModels from '../../models';
import * as fromActions from '../../store/actions';
import * as fromTypes from 'src/types';

export interface IAuthState {
  tempEmail: string | undefined; // signin // signup // forgetPassword
  userId: fromTypes.Maybe<string> | undefined; // signin // signup // forgetPassword
  verificationToken: fromTypes.Maybe<string> | undefined; // signin // signup
  isVerified: boolean; // signin

  signinPending: boolean;
  signinError: Error | undefined;

  signupPending: boolean;
  signupError: Error | undefined;

  logoutPending: boolean;
  logoutError: Error | undefined;

  forgetPasswordPending: boolean;
  forgetPasswordError: Error | undefined;

  resetPasswordPending: boolean;
  resetPasswordError: Error | undefined;

  resendVerificationPending: boolean;
  resendVerificationError: Error | undefined;

  verifyPending: boolean;
  verifyError: Error | undefined;

  userPending: boolean;
  userError: Error | undefined;
  user: fromTypes.UserResponse | undefined;
}

export const initialState: IAuthState = {
  tempEmail: undefined,
  userId: undefined,
  verificationToken: undefined,
  isVerified: false,

  signinPending: false,
  signinError: undefined,

  signupPending: false,
  signupError: undefined,

  logoutPending: false,
  logoutError: undefined,

  forgetPasswordPending: false,
  forgetPasswordError: undefined,

  resetPasswordPending: false,
  resetPasswordError: undefined,

  resendVerificationPending: false,
  resendVerificationError: undefined,

  verifyPending: false,
  verifyError: undefined,

  userPending: false,
  userError: undefined,
  user: undefined,
};

export const authReducer = createReducer(
  initialState,

  // Handle signin actions
  on(fromActions.signin, (state, { payload }) => ({
    ...state,
    signinPending: true,
    signinError: undefined,
    userId: undefined,
    verificationToken: undefined,
    isVerified: false,
    tempEmail: payload.email,
  })),
  on(fromActions.signinSuccess, (state, { payload }) => ({
    ...state,
    signinPending: false,
    userId: payload?.userId,
    verificationToken: payload?.verificationToken,
    isVerified: payload?.isVerified,
  })),
  on(fromActions.signinFailure, (state, { payload }) => ({
    ...state,
    signinPending: false,
    signinError: payload,
    tempEmail: undefined,
  })),

  // Handle signup actions
  on(fromActions.signup, (state, { payload }) => ({
    ...state,
    signupPending: true,
    signupError: undefined,
    tempEmail: payload.email,
  })),
  on(fromActions.signupSuccess, (state, { payload }) => ({
    ...state,
    signupPending: false,
    userId: payload.userId,
    verificationToken: payload.verificationToken,
  })),
  on(fromActions.signupFailure, (state, { payload }) => ({
    ...state,
    signupPending: false,
    signupError: payload,
    tempEmail: undefined,
  })),

  //handle load user
  on(fromActions.loadUser, (state) => ({
    ...state,
    userPending: true,
    userError: undefined,
  })),
  on(fromActions.loadUserSuccess, (state, { payload }) => ({
    ...state,
    userPending: false,
    user: payload,
    tempEmail: payload?.email,
  })),
  on(fromActions.loadUserFailure, (state, { payload }) => ({
    ...state,
    userPending: false,
    userError: payload,
  })),

  // Handle logout actions
  on(fromActions.logout, (state) => ({
    ...state,
    logoutPending: true,
    logoutError: undefined,
  })),
  on(fromActions.logoutSuccess, (state) => ({
    ...state,
    logoutPending: false,
  })),
  on(fromActions.logoutFailure, (state, { payload }) => ({
    ...state,
    logoutPending: false,
    logoutError: payload,
  })),

  // Handle forget password actions
  on(fromActions.forgetPassword, (state, { payload }) => ({
    ...state,
    forgetPasswordPending: true,
    forgetPasswordError: undefined,
    tempEmail: payload.email,
  })),
  on(fromActions.forgetPasswordSuccess, (state, { payload }) => ({
    ...state,
    forgetPasswordPending: false,
    userId: payload.userId,
  })),
  on(fromActions.forgetPasswordFailure, (state, { payload }) => ({
    ...state,
    forgetPasswordPending: false,
    forgetPasswordError: payload,
    tempEmail: undefined,
  })),

  // Handle reset password actions
  on(fromActions.resetPassword, (state) => ({
    ...state,
    resetPasswordPending: true,
    resetPasswordError: undefined,
  })),
  on(fromActions.resetPasswordSuccess, (state) => ({
    ...state,
    resetPasswordPending: false,
  })),
  on(fromActions.resetPasswordFailure, (state, { payload }) => ({
    ...state,
    resetPasswordPending: false,
    resetPasswordError: payload,
  })),

  // Handle resend verification actions
  on(fromActions.resendVerification, (state) => ({
    ...state,
    resendVerificationPending: true,
    resendVerificationError: undefined,
  })),
  on(fromActions.resendVerificationSuccess, (state) => ({
    ...state,
    resendVerificationPending: false,
  })),
  on(fromActions.resendVerificationFailure, (state, { payload }) => ({
    ...state,
    resendVerificationPending: false,
    resendVerificationError: payload,
  })),

  // Handle verify account actions
  on(fromActions.verifyAccount, (state) => ({
    ...state,
    verifyPending: true,
    verifyError: undefined,
  })),
  on(fromActions.verifyAccountSuccess, (state) => ({
    ...state,
    verifyPending: false,
  })),
  on(fromActions.verifyAccountFailure, (state, { payload }) => ({
    ...state,
    verifyPending: false,
    verifyError: payload,
  }))
);

export function reducer(
  state: IAuthState | undefined,
  action: Action
): IAuthState {
  return authReducer(state, action);
}

export const getTempEmail = (state: IAuthState) => state.tempEmail;
export const getUserId = (state: IAuthState) => state.userId;
export const getVerificationToken = (state: IAuthState) =>
  state.verificationToken;
export const getIsVerified = (state: IAuthState) => state.isVerified;

// Signin selectors
export const getSigninPending = (state: IAuthState) => state.signinPending;
export const getSigninError = (state: IAuthState) => state.signinError;

// Signup selectors
export const getSignupPending = (state: IAuthState) => state.signupPending;
export const getSignupError = (state: IAuthState) => state.signupError;

// Logout Selectors
export const getLogoutPending = (state: IAuthState) => state.logoutPending;
export const getLogoutError = (state: IAuthState) => state.logoutError;

// user selectors
export const getUserPending = (state: IAuthState) => state.userPending;
export const getUserError = (state: IAuthState) => state.userError;
export const getCurrentUser = (state: IAuthState) => state.user;

// Forget password selectors
export const getForgetPasswordPending = (state: IAuthState) =>
  state.forgetPasswordPending;
export const getForgetPasswordError = (state: IAuthState) =>
  state.forgetPasswordError;

// Reset password selectors
export const getResetPasswordPending = (state: IAuthState) =>
  state.resetPasswordPending;
export const getResetPasswordError = (state: IAuthState) =>
  state.resetPasswordError;

// Resend Verification selectors
export const getResendVerificationPending = (state: IAuthState) =>
  state.resendVerificationPending;
export const getResendVerificationError = (state: IAuthState) =>
  state.resendVerificationError;

// Verify Account selectors
export const getVerifyPending = (state: IAuthState) => state.verifyPending;
export const getVerifyError = (state: IAuthState) => state.verifyError;
