import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions';

export const timeZoneAdapter = createEntityAdapter<string>({
  selectId: (timezone) => timezone,
});

export interface ITimeZonesState extends EntityState<string> {
  pending: boolean;
  error: Error | undefined;
}

export const initialState: ITimeZonesState = timeZoneAdapter.getInitialState({
  pending: false,
  error: undefined,
});

export const timeZonesReducer = createReducer(
  initialState,

  // Handle loadTimeZones actions
  on(fromActions.loadTimeZones, (state) => {
    return timeZoneAdapter.setAll([], {
      ...state,
      pending: true,
      error: undefined,
    });
  }),
  on(fromActions.loadTimeZonesSuccess, (state, { payload }) =>
    timeZoneAdapter.setAll(payload, {
      ...state,
      pending: false,
    })
  ),
  on(fromActions.loadTimeZonesFailure, (state, { payload }) => ({
    ...state,
    pending: false,
    error: payload,
  }))
);

export function reducer(
  state: ITimeZonesState | undefined,
  action: Action
): ITimeZonesState {
  return timeZonesReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  timeZoneAdapter.getSelectors();

export const getTimeZonesPending = (state: ITimeZonesState) => state.pending;
export const getTimeZonesError = (state: ITimeZonesState) => state.error;
