import { createAction, props } from '@ngrx/store';
import * as fromTypes from 'src/types';

export const loadSystemLanguages = createAction('[App] load system languages');
export const loadSystemLanguagesSuccess = createAction(
  '[App] load system languages success',
  props<{ payload: fromTypes.LanguageResponse[] }>()
);
export const loadSystemLanguagesFailure = createAction(
  '[App] load system languages failure',
  props<{ payload: Error }>()
);
