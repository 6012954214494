import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const _filter = (
  options: { [key: string]: string | number }[],
  key: string,
  name: string
): { [key: string]: string | number }[] => {
  const filterValue = name.toLowerCase();

  return options.filter((option) =>
    (option[key] as string)?.toLowerCase().includes(filterValue)
  );
};

const _normalizeValue = (value: string): string =>
  value.toLowerCase().replace(/\s/g, '');

export const autocomplete = (
  options: { [key: string]: string | number }[],
  key: string
) =>
  function (source: Observable<{ [key: string]: string | number }>) {
    // console.log({ options });

    return source.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.name)),
      map((name) =>
        name ? _filter(options, key, name as string) : options.slice()
      )
      // map((value) =>
      //   _filter(
      //     options.map((option) => option[key] as string),
      //     value || ''
      //   )
      // )
    );
  };
