<p class="data" fxLayout="row" fxLayoutAlign="start center">
  <i
    [ngClass]="{
      'ph-check success': data?.type === 'SUCCESS',
      'ph-warning-circle failure': data?.type === 'FAILURE',
      'ph-warning warning': data?.type === 'WARNING'
    }"
  ></i>

  {{ data?.message | titlecase }}
</p>
