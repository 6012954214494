import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { mutation, query } from 'src/app/utils/rxjs-operators';
import * as fromGQL from 'src/generated/graphql';
import * as fromTypes from 'src/types';

@Injectable()
export class AuthService {
  constructor(
    private signinGQL: fromGQL.SigninGQL,
    private signupGQL: fromGQL.SignupGQL,
    private logoutGQL: fromGQL.LogoutGQL,
    private meGQL: fromGQL.MeGQL,
    private forgetPasswordGQL: fromGQL.ForgetPasswordGQL,
    private resetPasswordGQL: fromGQL.ResetPasswordGQL,
    private refreshTokenGQL: fromGQL.RefreshTokenGQL,
    private resendVerificationGQL: fromGQL.ResendVerificationGQL,
    private verifyAccountGQL: fromGQL.VerifyAccountGQL
  ) {}

  signin(
    credentials: fromTypes.SignupInput
  ): Observable<fromTypes.SigninResponse> {
    return this.signinGQL.mutate(credentials).pipe(mutation('signin'));
    // filter((result) => !!result?.data?.signin),
    // map((result) => result.data?.signin!)
  }

  signup(input: fromTypes.SignupInput): Observable<fromTypes.SignupResponse> {
    return this.signupGQL.mutate({ input }).pipe(mutation('signup'));
  }

  logout(): Observable<fromTypes.SuccessResponse> {
    return this.logoutGQL.mutate().pipe(mutation('logout'));
  }

  refreshToken(): Observable<fromTypes.RefreshTokenResponse> {
    return this.refreshTokenGQL.mutate().pipe(
      mutation('refreshToken'),
      tap(({ refreshToken, accessToken }) => {
        this.setRefreshToken(refreshToken);
        this.setAccessToken(accessToken);
      })
    );
  }

  setRefreshToken(token: string | null | undefined): void {
    if (token) {
      return localStorage.setItem('refreshToken', token);
    }
  }

  setAccessToken(token: string | null | undefined): void {
    if (token) {
      return localStorage.setItem('accessToken', token);
    }
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  getAccessToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  removeRefreshToken(): void {
    return localStorage.removeItem('refreshToken');
  }

  removeAccessToken(): void {
    return localStorage.removeItem('accessToken');
  }

  isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  forgetPassword(inputs: fromTypes.ForgetPasswordInput) {
    return this.forgetPasswordGQL
      .mutate(inputs)
      .pipe(mutation('forgetPassword'));
  }

  resetPassword(credentials: fromTypes.ResetPasswordInput) {
    return this.resetPasswordGQL
      .mutate(credentials)
      .pipe(mutation('resetPassword'));
  }

  verify(
    credentials: fromTypes.VerifyInput
  ): Observable<fromTypes.SigninResponse> {
    return this.verifyAccountGQL
      .mutate(credentials)
      .pipe(mutation('verifyAccount'));
  }

  resendVerification(
    credentials: fromTypes.ResendVerificationCodeInput
  ): Observable<fromTypes.SuccessResponse> {
    return this.resendVerificationGQL
      .mutate(credentials)
      .pipe(mutation('resendVerification'));
  }

  getCurrentUser(): Observable<fromTypes.UserResponse> {
    return this.meGQL.fetch().pipe(query('me'));
  }
}
