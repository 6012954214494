import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as fromMyOrganizationsReducer from '../reducers/my-organizations.reducer';

export const getMyOrganizationsSelectAll = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.selectAll
);

export const getMyOrganizationsSelectEntities = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.selectEntities
);

export const getMyOrganizationsSelectIds = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.selectIds
);

export const getMyOrganizationsSelectTotal = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.selectTotal
);

export const getMyOrganizationsPending = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.getMyOrganizationsPending
);

export const getMyOrganizationsError = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.getMyOrganizationsError
);

export const getMyOrganizationsCreatePending = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.getMyOrganizationsCreatePending
);

export const getMyOrganizationsCreateError = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.getMyOrganizationsCreateError
);

export const getMyOrganizationsCreated = createSelector(
  fromRoot.getMyOrganizationsState,
  fromMyOrganizationsReducer.getMyOrganizationsCreated
);
