import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgOtpInputModule } from 'ng-otp-input';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as fromContainers from './containers';
import { GraphQLModule } from './graphql.module';
import * as fromGuards from './guards';
import * as fromInterceptors from './interceptors';
import * as fromServices from './services';
import { LottieAnimationModule } from './shared-modules/stateless/lottie-animation/lottie-animation.module';
import { SnackBarModule } from './shared-modules/stateless/snack-bar/snack-bar.module';
import * as fromStore from './store';
import { CustomSerializer } from './utils/custom-serializer';

const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?date=${new Date().getTime()}`
  );

const MATERIALS = [MatDialogModule, MatSnackBarModule];
@NgModule({
  declarations: [AppComponent, ...fromContainers.CONTAINERS],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GraphQLModule,
    ...MATERIALS,
    ReactiveFormsModule,
    LoadingBarRouterModule,
    NgOtpInputModule, // should added here
    LottieAnimationModule,
    SnackBarModule,
    StoreModule.forRoot(fromStore.reducers, {
      metaReducers: [...fromStore.metaReducers],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot(fromStore.EFFECTS),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    ...fromServices.SERVICES,
    ...fromInterceptors.INTERCEPTORS_PROVIDERS,
    ...fromGuards.GUARDS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
